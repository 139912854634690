export const ko_KR = {
    "cs_2c_dashboard_abandoned": "중지됨",
    "cs_2c_dashboard_all": "모두",
    "cs_2c_dashboard_current_due_date": "현재 만료일",
    "cs_2c_dashboard_date": "날짜",
    "cs_2c_dashboard_days": "일",
    "cs_2c_dashboard_due_current_desc": "최근 만기일",
    "cs_2c_dashboard_due_later": "나중에 만료",
    "cs_2c_dashboard_due_original_desc": "생성 후 첫 만기일",
    "cs_2c_dashboard_due_today": "오늘 만료",
    "cs_2c_dashboard_end_of_day": "업무 종료 시간",
    "cs_2c_dashboard_go": "이동",
    "cs_2c_dashboard_hour": "시간",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "지연",
    "cs_2c_dashboard_launched": "시작됨",
    "cs_2c_dashboard_name": "이름",
    "cs_2c_dashboard_next": "다음",
    "cs_2c_dashboard_one_day": "24시",
    "cs_2c_dashboard_one_month": "1백만",
    "cs_2c_dashboard_one_week": "1W",
    "cs_2c_dashboard_open": "미해결",
    "cs_2c_dashboard_open_all": "미해결 항목 모두",
    "cs_2c_dashboard_open_all_desc": "모든 미해결 후속 조치",
    "cs_2c_dashboard_original_due_date": "원래 만료일",
    "cs_2c_dashboard_prev": "이전",
    "cs_2c_dashboard_previous": "이전",
    "cs_2c_dashboard_process_history": "처리 내역",
    "cs_2c_dashboard_process_history_loading": "로드 중......",
    "cs_2c_dashboard_process_history_reschedule": "다음으로 이 후속 조치 일정 조정:",
    "cs_2c_dashboard_process_history_transfer": "다음으로 이 후속 조치 이전:",
    "cs_2c_dashboard_process_one_week": "일주일",
    "cs_2c_dashboard_refine_by": "세부 분류 기준",
    "cs_2c_dashboard_rescheduled": "다시 예약됨",
    "cs_2c_dashboard_resolved": "해결됨",
    "cs_2c_dashboard_resolved_all": "해결 및 미해결 항목 모두",
    "cs_2c_dashboard_resolved_all_desc": "미해결 및 해결 후속 조치를 포함하여 지정된 기간 내에 속하는 모든 후속 조치",
    "cs_2c_dashboard_resolved_tab": "해결됨",
    "cs_2c_dashboard_results": "결과",
    "cs_2c_dashboard_supervisor_view": "감독자 보기",
    "cs_2c_dashboard_timespan": "기간",
    "cs_2c_dashboard_tomorrow": "내일",
    "cs_2c_dashboard_transferred": "전송됨",
    "cs_2c_dashboard_work_category": "업무 카테고리",
    "cs_gn_followup_dashboard": "후속 대시보드"
}