export const it_IT = {
    "cs_2c_dashboard_abandoned": "Abbandonato",
    "cs_2c_dashboard_all": "Tutto",
    "cs_2c_dashboard_current_due_date": "Data di consegna effettiva",
    "cs_2c_dashboard_date": "Data",
    "cs_2c_dashboard_days": "giorni",
    "cs_2c_dashboard_due_current_desc": "ultima scadenza",
    "cs_2c_dashboard_due_later": "Consegna prevista più tardi",
    "cs_2c_dashboard_due_original_desc": "prima scadenza dopo la creazione",
    "cs_2c_dashboard_due_today": "Consegna prevista oggi",
    "cs_2c_dashboard_end_of_day": "Fine giornata",
    "cs_2c_dashboard_go": "Vai",
    "cs_2c_dashboard_hour": "ora",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "In ritardo",
    "cs_2c_dashboard_launched": "Avviato",
    "cs_2c_dashboard_name": "Nome",
    "cs_2c_dashboard_next": "Successivo",
    "cs_2c_dashboard_one_day": "24 H",
    "cs_2c_dashboard_one_month": "1 M",
    "cs_2c_dashboard_one_week": "1 W",
    "cs_2c_dashboard_open": "Apri",
    "cs_2c_dashboard_open_all": "Tutto aperto",
    "cs_2c_dashboard_open_all_desc": "tutte le azioni di follow-up aperte",
    "cs_2c_dashboard_original_due_date": "Data di consegna originale",
    "cs_2c_dashboard_prev": "Precedente",
    "cs_2c_dashboard_previous": "Precedente",
    "cs_2c_dashboard_process_history": "Elabora cronologia",
    "cs_2c_dashboard_process_history_loading": "Caricamento in corso......",
    "cs_2c_dashboard_process_history_reschedule": "azione di follow-up riprogrammata su",
    "cs_2c_dashboard_process_history_transfer": "azione di follow-up trasferita su",
    "cs_2c_dashboard_process_one_week": "Una settimana",
    "cs_2c_dashboard_refine_by": "Filtra per",
    "cs_2c_dashboard_rescheduled": "Riprogrammato",
    "cs_2c_dashboard_resolved": "Risolto",
    "cs_2c_dashboard_resolved_all": "Tutto risolto e aperto",
    "cs_2c_dashboard_resolved_all_desc": "tutte le azioni di follow-up in uno specifico periodo di tempo, comprese quelle aperte e risolte",
    "cs_2c_dashboard_resolved_tab": "Risolto",
    "cs_2c_dashboard_results": "RISULTATI",
    "cs_2c_dashboard_supervisor_view": "Visualizzazione supervisore",
    "cs_2c_dashboard_timespan": "Arco temporale",
    "cs_2c_dashboard_tomorrow": "Domani",
    "cs_2c_dashboard_transferred": "Trasferito",
    "cs_2c_dashboard_work_category": "Categoria lavoro",
    "cs_gn_followup_dashboard": "Bacheca follow-up"
}