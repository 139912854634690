import {de} from './de';
import {en} from './en';
import {es} from './es';
import {fr} from './fr';
import {it} from './it';
import {ja} from './ja';
import {ko} from './ko';
import {pt} from './pt';
import {zh} from './zh';

export const languagePolyfill = {
    de,
    en,
    es,
    fr,
    it,
    ja,
    ko,
    pt,
    zh
};
