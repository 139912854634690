/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {getPhoenixAPI} from "../api/Phoenix";

const Auth: React.FC = () => {

    const [authResponse, setAuthResponse] = useState<string>('');

    useEffect(() => {
            getPhoenixAPI().testAuthCall()
                .then((response: Response) => {
                    return response.text();
                })
                .then((response: string) => {
                    setAuthResponse(response);
                })
                .catch(error => setAuthResponse('Error: ' + error));
        });

    return (
        <div>Authentication: {authResponse}</div>
    );
};

export default Auth;