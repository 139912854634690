import {getPhoenixQualifier} from "../Utilities";
import PhoenixAPI from "@amzn/csphoenix-react-client";
import { clientConfig } from "./ClientConfig";

const phoenixAPI: PhoenixAPI = new PhoenixAPI(getPhoenixQualifier(window.location) as string, "en_US", clientConfig);

export function getPhoenixAPI (): PhoenixAPI {
    return phoenixAPI;
}

export function authenticate (setFailureMethod:(e:string) => void): void {
    phoenixAPI.testAuthCall().then(r => {
        if (r.status === 401) {
            setFailureMethod("Authenticating");
            phoenixAPI.startAuth(e => {setFailureMethod(e)});       
        }
    });
}