import React, { useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import { ResolvedData, ResolvedTableEntry } from "../Interfaces";
import { FollowUpModal } from "./FollowUpModal"
import Button from "@amzn/awsui-components-react/polaris/button";
import Link from "@amzn/awsui-components-react/polaris/link";
import { i18n } from "../i18n/IntlManager";
import { getModalStatistics } from "../api/FollowUpApi";
import { getPhoenixAPI } from "../api/Phoenix";
import { getAliasRedirectUrl } from "../Utilities";
import {useCollection} from "@amzn/awsui-collection-hooks";

interface Props {
    data: ResolvedData
    marketplaceId: string
    obfuscatedId: string
    owner: string
}

const parseResolvedData = (data: ResolvedData): ResolvedTableEntry[] => {
    const resolvedList = data.resolvedStatisticsList;
    const list = JSON.parse(resolvedList)
    const items = list.map(resolvedStatistic => {
        return {
            userName: resolvedStatistic.userName,
            resolvedOnly: Number(resolvedStatistic.resolvedCreatedAndResolved.value),
            resolvedOpen: Number(resolvedStatistic.resolvedCreated.value),
            resolvedOnlyFollowUpIds: resolvedStatistic.resolvedCreatedAndResolved.followUpIdList,
            resolvedOpenFollowUpIds: resolvedStatistic.resolvedCreated.followUpIdList
        }
    });
    return items;

}



const ResolvedTable: React.FC<Props> = (props: Props) => {
    const { items, collectionProps } = useCollection(parseResolvedData(props.data), {
        sorting: {}
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<string[]>([]);
    const [modalError, setModalError] = useState<string>("");
    const [modalFetching, setModalFetching] = useState<boolean>(false);

    const onEntryClick = async (entryIds: string[]) => {
        setShowModal(true);
        setModalFetching(true);
        setModalData([]);
        const userId = getPhoenixAPI().jwtState?.content.id;
        try {
            const data = await getModalStatistics(entryIds, userId);
            setModalData(JSON.parse(data));
        } catch (err) {
            setModalError("Error retrieving data for follow up ids.")
        }
        setModalFetching(false);
    }

    const onUserNameClick = async (entryValue: string, marketplaceId: string) => {
        const url = await getAliasRedirectUrl(marketplaceId, entryValue, props.owner)

        return window.open(url)
    }

    const renderUserName =  (entryValue: string, marketplaceId: string) => {
        return (
            <Box>
                <Button variant="link" onClick={() => onUserNameClick(entryValue, marketplaceId)}>
                    <Link>{entryValue}
                    </Link>
                </Button>
            </Box>

        )
    };

    const onDismissHandler = () => {
        setShowModal(false);
    }


    const displayModal = () => {
        return <FollowUpModal marketplaceId={props.marketplaceId} fetching={modalFetching} modalData={modalData} visible={showModal} dismiss={onDismissHandler} error={modalError} />

    }

    const renderEntry = (entryValue: Number, entryIds: string[]): JSX.Element => {
        return entryValue === 0 ? (<Box textAlign="center">{entryValue}</Box>) :
            (<Box textAlign="center">
                <Button variant="link" onClick={() => onEntryClick(entryIds)}>
                    <Box color="text-status-error"><u>{entryValue}</u>
                    </Box>
                </Button>
            </Box>)
    }

    return (
        <>
            <Table
                trackBy="name"
                {...collectionProps}
                columnDefinitions={[
                    {
                        id: "name",
                        header: i18n("cs_2c_dashboard_name"),
                        cell: e => renderUserName(e.userName, props.marketplaceId),
                        sortingField: "userName"
                    },
                    {
                        id: "resolvedOnly",
                        header: i18n("cs_2c_dashboard_resolved"),
                        cell: e => renderEntry(e.resolvedOnly, e.resolvedOnlyFollowUpIds),
                        sortingField: "resolvedOnly"
                    },
                    {
                        id: "allResolvedOpen",
                        header: i18n("cs_2c_dashboard_resolved_all"),
                        cell: e => renderEntry(e.resolvedOpen, e.resolvedOpenFollowUpIds),
                        sortingField: "resolvedOpen"

                    }
                ]}
                items={items}
                loadingText="Loading resources"
            />
            {displayModal()}

        </>

    );
}

export default ResolvedTable;