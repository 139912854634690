export const es_MX = {
    "cs_2c_dashboard_abandoned": "Abandonado",
    "cs_2c_dashboard_all": "Todo",
    "cs_2c_dashboard_current_due_date": "Fecha límite actual",
    "cs_2c_dashboard_date": "Fecha",
    "cs_2c_dashboard_days": "días",
    "cs_2c_dashboard_due_current_desc": "la última fecha límite",
    "cs_2c_dashboard_due_later": "Caduca más tarde",
    "cs_2c_dashboard_due_original_desc": "la primera fecha límite desde la creación",
    "cs_2c_dashboard_due_today": "Caduca hoy",
    "cs_2c_dashboard_end_of_day": "Final del día",
    "cs_2c_dashboard_go": "Ir",
    "cs_2c_dashboard_hour": "hora",
    "cs_2c_dashboard_id": "Id.",
    "cs_2c_dashboard_late": "Demorado",
    "cs_2c_dashboard_launched": "Iniciado",
    "cs_2c_dashboard_name": "Nombre",
    "cs_2c_dashboard_next": "Siguiente",
    "cs_2c_dashboard_one_day": "24 horas",
    "cs_2c_dashboard_one_month": "1 minuto",
    "cs_2c_dashboard_one_week": "1 minuto",
    "cs_2c_dashboard_open": "Abrir",
    "cs_2c_dashboard_open_all": "Todos abiertos",
    "cs_2c_dashboard_open_all_desc": "todos los seguimientos abiertos",
    "cs_2c_dashboard_original_due_date": "Fecha límite original",
    "cs_2c_dashboard_prev": "Anterior",
    "cs_2c_dashboard_previous": "Anterior",
    "cs_2c_dashboard_process_history": "Historial de procesamiento",
    "cs_2c_dashboard_process_history_loading": "Cargando...",
    "cs_2c_dashboard_process_history_reschedule": "se reprogramó este seguimiento para",
    "cs_2c_dashboard_process_history_transfer": "se transfirió este seguimiento a",
    "cs_2c_dashboard_process_one_week": "Una semana",
    "cs_2c_dashboard_refine_by": "Refinar por",
    "cs_2c_dashboard_rescheduled": "Reprogramado",
    "cs_2c_dashboard_resolved": "Resuelto",
    "cs_2c_dashboard_resolved_all": "Todos resueltos y abiertos",
    "cs_2c_dashboard_resolved_all_desc": "todos los seguimientos en un plazo determinado, incluso los abiertos y los resueltos.",
    "cs_2c_dashboard_resolved_tab": "Resuelto",
    "cs_2c_dashboard_results": "RESULTADOS",
    "cs_2c_dashboard_supervisor_view": "Vista del supervisor",
    "cs_2c_dashboard_timespan": "Lapso de tiempo",
    "cs_2c_dashboard_tomorrow": "Mañana",
    "cs_2c_dashboard_transferred": "Transferido",
    "cs_2c_dashboard_work_category": "Categoría de trabajo",
    "cs_gn_followup_dashboard": "Panel de seguimiento"
}