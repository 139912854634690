import { getPhoenixAPI } from "../../api/Phoenix"
import { MetricType } from "@amzn/csphoenix-react-client";

const SUPPORTED_LOCALES = [
    'ar_AE',
    'cs_CZ',
    'de_DE',
    'en_AU',
    'en_CA',
    'en_GB',
    'en_IN',
    'en_US',
    'es_ES',
    'es_MX',
    'fr_CA',
    'fr_FR',
    'it_IT',
    'ja_JP',
    'ko_KR',
    'nl_NL',
    'pl_PL',
    'pt_BR',
    'pt_PT',
    'tr_TR',
    'zh_CN',
    'zh_TW'
];

const SUBSTITUTE_LOCALES = {
    'ar': 'ar_AE',
    'cs': 'cs_CZ',
    'de': 'de_DE',
    'en': 'en_US',
    'es': 'es_MX', // for es_419, es_AR, es_BO, es_CL, ... (for Latin America locales)
    'fr': 'fr_FR', // for fr_CH
    'it': 'it_IT',
    'ja': 'ja_JP',
    'ko': 'ko_KR',
    'nl': 'nl_NL',
    'pl': 'pl_PL',
    'pt': 'pt_PT',
    'tr': 'tr_TR',
    'zh': 'zh_TW' // for zh_HK
};

const DEFAULT_LOCALE = 'en_US';

export function getSupportedLocale(locale: string): string {
    if (!locale) {
        console.warn("No locale found, using default locale: " + DEFAULT_LOCALE);
        return DEFAULT_LOCALE;
    }
    const [language, country] = locale.split('_');
    //for safari support because it returns a different format for locale
    const formattedLocale = language + (country ? '_' + country?.toUpperCase() : '');
    if (SUPPORTED_LOCALES.includes(formattedLocale)) {
        return formattedLocale;
    }
    getPhoenixAPI().addMetric(`FollowupDashboard.UnsupportedLocale.${locale}`, 1, MetricType.COUNT);
    if (SUBSTITUTE_LOCALES[language]) {
        const substitute = SUBSTITUTE_LOCALES[language];
        console.warn(`Unsupported locale: ${locale}. Using substitute locale: ${substitute}`);
        return substitute;
    }
    console.warn(`Unsupported locale: ${locale}. Using default locale: ${DEFAULT_LOCALE}`);
    return DEFAULT_LOCALE;
}