export const pl_PL = {
    "cs_2c_dashboard_abandoned": "Opuszczono",
    "cs_2c_dashboard_all": "Wszystkie",
    "cs_2c_dashboard_current_due_date": "Bieżący termin",
    "cs_2c_dashboard_date": "Data",
    "cs_2c_dashboard_days": "dni",
    "cs_2c_dashboard_due_current_desc": "najpóźniejszy termin",
    "cs_2c_dashboard_due_later": "Na później",
    "cs_2c_dashboard_due_original_desc": "pierwszy termin po utworzeniu",
    "cs_2c_dashboard_due_today": "Na dziś",
    "cs_2c_dashboard_end_of_day": "Koniec dnia",
    "cs_2c_dashboard_go": "Uruchom",
    "cs_2c_dashboard_hour": "godzina",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "Opóźniony",
    "cs_2c_dashboard_launched": "Rozpoczęto",
    "cs_2c_dashboard_name": "Nazwa",
    "cs_2c_dashboard_next": "Dalej",
    "cs_2c_dashboard_one_day": "g",
    "cs_2c_dashboard_one_month": "m",
    "cs_2c_dashboard_one_week": "1W",
    "cs_2c_dashboard_open": "Otwórz",
    "cs_2c_dashboard_open_all": "Wszystkie otwarte",
    "cs_2c_dashboard_open_all_desc": "wszystkie otwarte działania następcze",
    "cs_2c_dashboard_original_due_date": "Początkowy termin",
    "cs_2c_dashboard_prev": "Poprz.",
    "cs_2c_dashboard_previous": "Poprzedni",
    "cs_2c_dashboard_process_history": "Historia procesu",
    "cs_2c_dashboard_process_history_loading": "Wczytywanie......",
    "cs_2c_dashboard_process_history_reschedule": "to działanie następcze zostało zaplanowane na",
    "cs_2c_dashboard_process_history_transfer": "to działanie następcze zostało przeniesione do",
    "cs_2c_dashboard_process_one_week": "Jeden tydzień",
    "cs_2c_dashboard_refine_by": "Zawęź według",
    "cs_2c_dashboard_rescheduled": "Zmiana terminu",
    "cs_2c_dashboard_resolved": "Rozwiązany",
    "cs_2c_dashboard_resolved_all": "Wszystkie rozwiązane i otwarte",
    "cs_2c_dashboard_resolved_all_desc": "wszystkie działania następcze w danym okresie, włączając otwarte i rozwiązane.",
    "cs_2c_dashboard_resolved_tab": "Rozwiązany",
    "cs_2c_dashboard_results": "WYNIKI",
    "cs_2c_dashboard_supervisor_view": "Widok przełożonego",
    "cs_2c_dashboard_timespan": "Czas",
    "cs_2c_dashboard_tomorrow": "Jutro",
    "cs_2c_dashboard_transferred": "Przeniesiono",
    "cs_2c_dashboard_work_category": "Kategoria pracy",
    "cs_gn_followup_dashboard": "Pulpit dla działań typu Follow-up"
}