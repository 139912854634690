import {ClientConfig} from "@amzn/csphoenix-react-client";

export const clientConfig: Record<string, ClientConfig> = {
  'local': {
    tokenCookieName: "pgw-local.token",
    redirectEndpoint: "https://local.pgw.cs.a2z.com:3000/",
    clientStateCookieName: "peacock-local",
    clientId: "pgw-local"
  },
  'beta': {
    tokenCookieName: "pgw-test.token",
    redirectEndpoint: "https://beta.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-test",
    clientId: "pgw-test"
  },
  'gamma-na': {
    tokenCookieName: "pgw-prod.token",
    redirectEndpoint: "https://gamma-na.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-na",
    clientId: "pgw-prod"
  },
  'gamma-eu': {
    tokenCookieName: "pgw-prod-eu.token",
    redirectEndpoint: "https://gamma-eu.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-eu",
    clientId: "pgw-prod-eu"
  },
  'gamma-fe': {
    tokenCookieName: "pgw-prod-fe.token",
    redirectEndpoint: "https://gamma-fe.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-fe",
    clientId: "pgw-prod-fe"
  },
  'gamma-cn': {
    tokenCookieName: "pgw-prod-cn.token",
    redirectEndpoint: "https://gamma-cn.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-cn",
    clientId: "pgw-prod-cn"
  },
  'prod-na': {
    tokenCookieName: "pgw-prod.token",
    redirectEndpoint: "https://prod-na.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-na",
    clientId: "pgw-prod"
  },
  'prod-eu': {
    tokenCookieName: "pgw-prod-eu.token",
    redirectEndpoint: "https://prod-eu.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-eu",
    clientId: "pgw-prod-eu"
  },
  'prod-fe': {
    tokenCookieName: "pgw-prod-fe.token",
    redirectEndpoint: "https://prod-fe.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-fe",
    clientId: "pgw-prod-fe"
  },
  'prod-cn': {
    tokenCookieName: "pgw-prod-cn.token",
    redirectEndpoint: "https://prod-cn.peacock.pgw.cs.a2z.com/",
    clientStateCookieName: "peacock-prod-cn",
    clientId: "pgw-prod-cn"
  }
};