import React from "react";
import "font-awesome/css/font-awesome.min.css";
import AppLayout from "@amzn/awsui-components-react/polaris/app-layout"
import { en_US } from "../i18n/en_US";
import Routes from "../routes";
import IntlManager from "../i18n/IntlManager";
import { peacockMessages } from "../i18n/peacock/peacock";
import { getSupportedLocale } from "../i18n/util/LocaleUtils";

const App = () => {
    //this line is to make the browser locale follow the same format as ours i.e. en-US becomes en_US
    const browserLocale = navigator.language?.replace("-", "_");
    const locale = getSupportedLocale(browserLocale);
    IntlManager.sharedManager = new IntlManager(locale, peacockMessages[locale], en_US);

    return (
        <div className="awsui">
            <AppLayout
                content={Routes}
                toolsHide={true}
                navigationHide={true}
            />
        </div>
    );
};

export default App;