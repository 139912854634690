export const es_ES = {
    "cs_2c_dashboard_abandoned": "Abandonado",
    "cs_2c_dashboard_all": "Todo",
    "cs_2c_dashboard_current_due_date": "Fecha de entrega actual",
    "cs_2c_dashboard_date": "Fecha",
    "cs_2c_dashboard_days": "días",
    "cs_2c_dashboard_due_current_desc": "la fecha límite más tardía",
    "cs_2c_dashboard_due_later": "Entrega más tarde",
    "cs_2c_dashboard_due_original_desc": "la primera fecha de plazo tras la creación",
    "cs_2c_dashboard_due_today": "Entrega hoy",
    "cs_2c_dashboard_end_of_day": "Final del día",
    "cs_2c_dashboard_go": "Ir",
    "cs_2c_dashboard_hour": "hora",
    "cs_2c_dashboard_id": "Id.",
    "cs_2c_dashboard_late": "Tarde",
    "cs_2c_dashboard_launched": "Lanzado",
    "cs_2c_dashboard_name": "Nombre",
    "cs_2c_dashboard_next": "Siguiente",
    "cs_2c_dashboard_one_day": "24 H",
    "cs_2c_dashboard_one_month": "1 M",
    "cs_2c_dashboard_one_week": "1 SEM",
    "cs_2c_dashboard_open": "Abrir",
    "cs_2c_dashboard_open_all": "Todos los abiertos",
    "cs_2c_dashboard_open_all_desc": "todos los seguimientos abiertos",
    "cs_2c_dashboard_original_due_date": "Fecha de entrega original",
    "cs_2c_dashboard_prev": "Anterior",
    "cs_2c_dashboard_previous": "Anterior",
    "cs_2c_dashboard_process_history": "Procesal historial",
    "cs_2c_dashboard_process_history_loading": "Cargando......",
    "cs_2c_dashboard_process_history_reschedule": "se ha cambiado el plazo de este seguimiento a",
    "cs_2c_dashboard_process_history_transfer": "se ha transferido este seguimiento a",
    "cs_2c_dashboard_process_one_week": "Una semana",
    "cs_2c_dashboard_refine_by": "Refinar por",
    "cs_2c_dashboard_rescheduled": "Cambio en la hora de entrega",
    "cs_2c_dashboard_resolved": "Resuelto",
    "cs_2c_dashboard_resolved_all": "Todos los resueltos y abiertos",
    "cs_2c_dashboard_resolved_all_desc": "todos los seguimientos de un periodo determinado, incluidos abiertos y resueltos.",
    "cs_2c_dashboard_resolved_tab": "Resuelto",
    "cs_2c_dashboard_results": "RESULTADOS",
    "cs_2c_dashboard_supervisor_view": "Vista de supervisor",
    "cs_2c_dashboard_timespan": "Intervalo de tiempo",
    "cs_2c_dashboard_tomorrow": "Mañana",
    "cs_2c_dashboard_transferred": "Transferido",
    "cs_2c_dashboard_work_category": "Categoría de trabajo",        
    "cs_gn_followup_dashboard": "Tablero de seguimiento"

}