export const nl_NL = {
    "cs_2c_dashboard_abandoned": "Geannuleerd",
    "cs_2c_dashboard_all": "Alle",
    "cs_2c_dashboard_current_due_date": "Huidige vervaldatum",
    "cs_2c_dashboard_date": "Datum",
    "cs_2c_dashboard_days": "dagen",
    "cs_2c_dashboard_due_current_desc": "de laatste leverdatum",
    "cs_2c_dashboard_due_later": "Vervalt later",
    "cs_2c_dashboard_due_original_desc": "de eerste leverdatum na aanmaak",
    "cs_2c_dashboard_due_today": "Vervalt vandaag",
    "cs_2c_dashboard_end_of_day": "Einde van de dag",
    "cs_2c_dashboard_go": "Start",
    "cs_2c_dashboard_hour": "uur",
    "cs_2c_dashboard_id": "Id",
    "cs_2c_dashboard_late": "Te laat",
    "cs_2c_dashboard_launched": "Uitgegeven",
    "cs_2c_dashboard_name": "Naam",
    "cs_2c_dashboard_next": "Volgende",
    "cs_2c_dashboard_one_day": "24 u",
    "cs_2c_dashboard_one_month": "1 m",
    "cs_2c_dashboard_one_week": "1 w",
    "cs_2c_dashboard_open": "Openen",
    "cs_2c_dashboard_open_all": "Alle open",
    "cs_2c_dashboard_open_all_desc": "alle open follow-ups",
    "cs_2c_dashboard_original_due_date": "Oorspronkelijke vervaldatum",
    "cs_2c_dashboard_prev": "Vorige",
    "cs_2c_dashboard_previous": "Vorige",
    "cs_2c_dashboard_process_history": "Procesoverzicht",
    "cs_2c_dashboard_process_history_loading": "Wordt geladen ...",
    "cs_2c_dashboard_process_history_reschedule": "deze follow-up is opnieuw gepland",
    "cs_2c_dashboard_process_history_transfer": "deze follow-up is overgedragen",
    "cs_2c_dashboard_process_one_week": "Een week",
    "cs_2c_dashboard_refine_by": "Verfijnen op",
    "cs_2c_dashboard_rescheduled": "Opnieuw gepland",
    "cs_2c_dashboard_resolved": "Opgelost",
    "cs_2c_dashboard_resolved_all": "Alle opgelost en open",
    "cs_2c_dashboard_resolved_all_desc": "alle follow-ups binnen een bepaald tijdsbestek, ook open en opgelost.",
    "cs_2c_dashboard_resolved_tab": "Opgelost",
    "cs_2c_dashboard_results": "RESULTATEN",
    "cs_2c_dashboard_supervisor_view": "Supervisoroverzicht",
    "cs_2c_dashboard_timespan": "Tijdsduur",
    "cs_2c_dashboard_tomorrow": "Morgen",
    "cs_2c_dashboard_transferred": "Verplaatst",
    "cs_2c_dashboard_work_category": "Werkcategorie",
    "cs_gn_followup_dashboard": "Follow-up-dashboard"
}