import * as types from "../actions/types";
 
const filter = (state = "", action) => {
  switch (action.type) {
    case types.FILTER:
      return action.filter;
    default:
      return state;
  }
};
 
export default filter;