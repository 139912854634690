export const pt_PT = {
    "cs_2c_dashboard_abandoned": "Abandonados",
    "cs_2c_dashboard_all": "Tudo",
    "cs_2c_dashboard_current_due_date": "Data de vencimento atual",
    "cs_2c_dashboard_date": "Data",
    "cs_2c_dashboard_days": "dias",
    "cs_2c_dashboard_due_current_desc": "a última data de vencimento",
    "cs_2c_dashboard_due_later": "Vence mais tarde",
    "cs_2c_dashboard_due_original_desc": "a primeira data de vencimento após a criação",
    "cs_2c_dashboard_due_today": "Vence hoje",
    "cs_2c_dashboard_end_of_day": "Fim do dia",
    "cs_2c_dashboard_go": "Ir",
    "cs_2c_dashboard_hour": "hora",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "Atrasado",
    "cs_2c_dashboard_launched": "Lançado",
    "cs_2c_dashboard_name": "Nome",
    "cs_2c_dashboard_next": "Seguinte",
    "cs_2c_dashboard_one_day": "24H",
    "cs_2c_dashboard_one_month": "1M",
    "cs_2c_dashboard_one_week": "1W",
    "cs_2c_dashboard_open": "Abrir",
    "cs_2c_dashboard_open_all": "Todos Abertos",
    "cs_2c_dashboard_open_all_desc": "Todos os seguimentos em aberto.",
    "cs_2c_dashboard_original_due_date": "Data de vencimento original",
    "cs_2c_dashboard_prev": "Anterior",
    "cs_2c_dashboard_previous": "Anterior",
    "cs_2c_dashboard_process_history": "Histórico do processo",
    "cs_2c_dashboard_process_history_loading": "A carregar...",
    "cs_2c_dashboard_process_history_reschedule": "reagendou este seguimento de",
    "cs_2c_dashboard_process_history_transfer": "transferiu este seguimento de",
    "cs_2c_dashboard_process_one_week": "Uma semana",
    "cs_2c_dashboard_refine_by": "Refinar por",
    "cs_2c_dashboard_rescheduled": "Reagendado",
    "cs_2c_dashboard_resolved": "Apenas resolvidos",
    "cs_2c_dashboard_resolved_all": "Todos resolvidos e abertos",
    "cs_2c_dashboard_resolved_all_desc": "Todos os seguimentos dentro do período selecionado (24H/1S/1M).",
    "cs_2c_dashboard_resolved_tab": "Resolvidos",
    "cs_2c_dashboard_results": "RESULTADOS",
    "cs_2c_dashboard_supervisor_view": "Vista de supervisor",
    "cs_2c_dashboard_timespan": "Período de tempo",
    "cs_2c_dashboard_tomorrow": "Amanhã",
    "cs_2c_dashboard_transferred": "Transferidos",
    "cs_2c_dashboard_work_category": "Categoria de trabalho",
    "cs_gn_followup_dashboard": "Painel de acompanhamento"
}