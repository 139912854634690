import { authenticate, getPhoenixAPI } from "./Phoenix";
import {OpenData, OpenStatistic, ResolvedData, ResolveResponseData, WorkCategory} from "../Interfaces";

export const getMarketplaceId = (): string => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    //url will always have marketplace ID because we are passing it in through redirect
    if (urlParams.has('marketplaceId')) {
        return urlParams.get('marketplaceId') as string;
    }

    return "";
}

export const getActiveFollowUps = async (searchValue: string, user: string, owner: string): Promise<OpenData> => {
    const openContract = {
        contractId: "GetActiveFollowupUserStatistics",
        projectName: "FollowupDashboard",
        fulfillmentArguments:
            {
                marketplaceId: getMarketplaceId(),
                requestingUserId: user,
                pageNum: "1",
                pageSize: "50",
                workCategoryList: "",
                userName: searchValue,
                ownerName: owner,
                sortColumn: "UserName",
                sortOrder: "ASC"
            }
    }

    return new Promise<OpenData>(async (resolve) => {

        let statisticsList : OpenStatistic[] = [];
        let workList : WorkCategory[] = [];
        let totalCount = 0;
        let doBreak = false;
        do {
            // eslint-disable-next-line
            const resData = await getPhoenixAPI().callPhoenixSlapshot(openContract);
            //this is to trigger re-auth when user is going from papyrus back to followup dashboard just in case token is messed up
            if (resData?.response.status === 401) {
                authenticate(() => { });
            }
            if (resData.response.ok) {
                if (resData?.json?.Plugin_FormatActiveStatistics?.data) {
                    let responseData = resData.json.Plugin_FormatActiveStatistics.data[0] as OpenData;

                    totalCount = Number(responseData.totalCount);
                    statisticsList = statisticsList.concat(JSON.parse(responseData.statisticsList));
                    workList = workList.concat(responseData.workCategoryList);

                    openContract.fulfillmentArguments.pageNum = (Number(openContract.fulfillmentArguments.pageNum) + 1).toString();
                } else {
                    doBreak = true;
                }
            } else {
                throw new Error("Failed to fetch active followups.");
            }
        } while (statisticsList.length < totalCount && !doBreak);

        resolve({
            workCategoryList: workList,
            totalCount: totalCount.toString(),
            statisticsList: JSON.stringify(statisticsList),
        })
    });
}


export const getResolvedFollowUps = (searchValue: string, user: string, owner: string): Promise<ResolveResponseData> => {
    let resolvedContract = {
        contractId: "GetResolvedStatisticsForUserId",
        projectName: "FollowupDashboard",
        fulfillmentArguments:
            {
                marketplaceId: getMarketplaceId(),
                requestingUserId: user,
                pageNum: "1",
                pageSize: "50",
                workCategoryList: "",
                userName: searchValue,
                ownerName: owner,
                sortColumn: "UserName",
                sortOrder: "ASC"

            }
    }

    return new Promise<ResolveResponseData>(async (resolve) => {

        let statisticsList : OpenStatistic[] = [];
        let workList : WorkCategory[] = [];
        let totalCount = 0;
        let obfuscatedId = "";
        let doBreak = false;
        do {
            // eslint-disable-next-line
            const resData = await getPhoenixAPI().callPhoenixSlapshot(resolvedContract);
            //this is to trigger re-auth when user is going from papyrus back to followup dashboard just in case token is messed up
            if (resData?.response.status === 401) {
                authenticate(() => { });
            }
            if (resData.response.ok) {
                if (resData?.json?.toObfuscateId?.data) {
                    obfuscatedId = resData?.json?.toObfuscateId?.data[0] as string;
                }

                if (resData?.json?.resolvedFollowupUserStatisticsPlugin?.data) {
                    let resolveData = resData.json.resolvedFollowupUserStatisticsPlugin.data[0] as ResolvedData;

                    totalCount = Number(resolveData.totalCount);
                    statisticsList = statisticsList.concat(JSON.parse(resolveData.resolvedStatisticsList));
                    workList = workList.concat(resolveData.workCategoryList);

                    resolvedContract.fulfillmentArguments.pageNum = (Number(resolvedContract.fulfillmentArguments.pageNum) + 1).toString();
                } else {
                    doBreak = true;
                }
            } else {
                throw new Error("Failed to fetch resolved followups.");
            }
        } while (statisticsList.length < totalCount && !doBreak);

        resolve({
            responseData: {
                workCategoryList: workList,
                totalCount: totalCount.toString(),
                resolvedStatisticsList: JSON.stringify(statisticsList),
            },
            obfuscatedId: obfuscatedId
        })
    });
}

export const getModalStatistics = (ids: string[], userId: string): Promise<string> => {
    //Although this call seems like it is paginated, it is not. The result stays the same regardless of the pageNum
    //Since this should be fixed on the API side, we are leaving code here as is and make it only do 1 call.
    let followupStatisticsContract = {
        contractId: "FollowUpProcessStatistics",
        projectName: "FollowupDashboard",
        fulfillmentArguments:
        {
            marketplaceId: getMarketplaceId(),
            //using this random dummy data for requestingUserId because it does not validate the value but rather needs it there in the right format
            //working on a better solution to obfuscate the ids 
            requestingUserId: "",
            pageNum: "1",
            pageSize: "50",
            workCategoryList: "",
            sortColumn: "UserName",
            sortOrder: "ASC",
            followUpIdList: JSON.stringify(ids)

        }
    }
    return new Promise<string>(async (resolve) => {

        let statisticsList = [];

        const resData = await getPhoenixAPI().callPhoenixSlapshot(followupStatisticsContract);
        //this is to trigger re-auth when user is going from papyrus back to followup dashboard just in case token is messed up
        if (resData?.response.status === 401) {
            authenticate(() => { });
        }
        if (resData.response.ok) {
            if (resData?.json?.FollowUpProcessStatisticsPluginNode?.data) {
                statisticsList = statisticsList.concat(JSON.parse(resData?.json?.FollowUpProcessStatisticsPluginNode?.data[0].statisticsList));
            }
        } else {
            throw new Error("Failed to fetch modal followup statistics. " + resData.response.status + ": " + resData.response.statusText);
        }

        resolve(JSON.stringify(statisticsList));
    });
}

export const getObfuscatedUserId = (id: string, owner: string): Promise<string> => {
    let obfuscatedContract = {
        contractId: "GetObfuscatedIdForUserName",
        projectName: "FollowupDashboard",
        fulfillmentArguments: {
            userName: id,
            ownerName: owner
        }
    }

    return getPhoenixAPI().callPhoenixSlapshot(obfuscatedContract).then(resData => {
        if(resData.response.ok){
            if(resData?.json?.toObfuscateId.data){
                return resData.json.toObfuscateId.data[0]
            }else {
                throw new Error("Error obfuscating id.");
            }
        } else {
            throw new Error ("Redirect cannot occur.");
        }
    })
}