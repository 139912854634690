const modalMapping: Record<string, string> = {
    'local': "https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/",
    'beta': "https://cscentral-na-beta.vipinteg.amazon.com/gp/stores/",
    'gamma': 'https://cscentral-gamma.amazon.com/gp/stores/',
    'gamma-na': 'https://cscentral-gamma.amazon.com/gp/stores/',
    'gamma-eu': 'https://cscentral-gamma-eu.amazon.com/gp/stores/',
    'gamma-fe': 'https://cscentral-gamma-fe.amazon.com/gp/stores/',
    'gamma-cn': 'https://cscentral-gamma-cn.amazon.com/gp/stores/',
    'prod': 'https://cscentral.amazon.com/gp/stores/',
    'prod-na': 'https://cscentral.amazon.com/gp/stores/',
    'prod-eu': 'https://cscentral-eu.amazon.com/gp/stores/',
    'prod-cn': 'https://cscentral-cn.amazon.com/gp/stores/',
    'prod-fe': 'https://cscentral-fe.amazon.com/gp/stores/',
}

export const getModalMapping = (env: string): string => {
    return modalMapping[env];
}

export const getMarketplaceMapping = (marketplaceId: string): string => {
    return marketplaceToStringMarketplaceMapping[marketplaceId];
}

const marketplaceToStringMarketplaceMapping: Record<string, string> = {
    "ATVPDKIKX0DER": "www.amazon.com",
    "A1F83G8C2ARO7P": "www.amazon.co.uk",
    "A1RKKUPIHCS9HS": "www.amazon.es",
    "AJZF8LZ1EJVJN": "www.amazon.es",
    "A13V1IB3VIYZZH": "www.amazon.fr",
    "APJ6JRA9NG5V4": "www.amazon.it",
    "A3HOBANJMCMD83": "www.amazon.it",
    "A1PA6795UKMFR9": "www.amazon.de",
    "A1805IZSGTT6HS": "www.amazon.nl",
    "A1M3WC0SJ3A38T": "www.amazon.nl",
    "A39IBJ37TRP1C6": "www.amazon.com.au",
    "A1RNPCQ4K8U27I": "www.amazon.com.au",
    "A21TJRUUN4KGV": "www.amazon.in",
    "A2XZLSVIQ0F4JT": "www.amazon.in",
    "A2EUQ1WTGCTBG2": "www.amazon.ca",
    "A1AM78C64UM0Y8": "www.amazon.com.mx",
    "A3P3J5A7D2ZVXI": "www.amazon.com.mx",
    "A2Q3Y263D00KWC": "www.amazon.com.br",
    "AZXD3QD5B39HD": "www.amazon.com.br",
    "A1VC38T7YXB528": "www.amazon.co.jp",
    "AAHKV2X7AFYLW": "www.amazon.cn",
    "A33AVAJ2PDY3EV": "www.amazon.com.tr",
    "A3CQBQD3RGPJR8": "www.amazon.com.tr",
    "A1GH8JQC28KQMO": "FPA.abebooks.co.uk",
    "A3HJ3LHVHW6R8S": "FPA.abebooks.com",
    "A10A3N4DLO63GE": "FPA.abebooks.de",
    "A1MHIT7LTD3TXB": "FPA.abebooks.es",
    "A3XA1DPPGYA9H": "FPA.abebooks.fr",
    "A14JTEK4O1XNK4": "FPA.abebooks.it",
    "A2D32KE73PNS33": "www.amazondistribution.in",
    "A1D38IXLEJ6VQC": "www.amazondistribution.in",
    "A1VA89I9GO62U2": "amazonfreshstores.amazon.com",
    "A332POBM3AVU51": "amazonfreshstores.amazon.com",
    "A3P3DP59YHPL03": "amazonfreshstores.amazon.co.uk",
    "A260JPYZJUXIHF": "amazongo.amazon.com",
    "A2JOQTGNQAFDDD": "amazongo.amazon.com",
    "A25NTRYU0OUV0L": "amazonregistry.com",
    "A2CQZ5RBY40XE": "www.audible.ca",
    "A16QVBR1WHSTVN": "www.audible.ca",
    "A1QAP3MOU4173J": "www.audible.co.jp",
    "A298HDQ29ZA38N": "www.audible.co.jp",
    "A2I9A3Q2GNFNGQ": "www.audible.co.uk",
    "A3G8SHMNK6Y7PK": "www.audible.co.uk",
    "AF2M0KC94RCEA": "www.audible.com",
    "A3G3TQ0ISIOPG8": "www.audible.com",
    "AN7EY7DTAW63G": "www.audible.com.au",
    "A2AT07N6OMIWFU": "www.audible.com.au",
    "AN7V1F1VY261K": "www.audible.de",
    "ABMF2NRWK6Q4Q": "www.audible.de",
    "ALMIKO4SZCSAR": "www.audible.es",
    "ARV4E8CS3V0UR": "www.audible.es",
    "A2728XDNODOQ8T": "www.audible.fr",
    "A1PM6UBHJK3SLV": "www.audible.fr",
    "AJO3FBRUE6J4S": "www.audible.in",
    "A2ITDZXD3G7XX6": "www.audible.in",
    "A2N7FU2W2BU2ZC": "www.audible.it",
    "ARGSKDDBM3ISN": "www.audible.it",
    "A12QK8IU0H0XW5": "aws.amazon.com",
    "A3U2NSBAPCSC3W": "aws.amazon.com",
    "AI2AE4A16O33": "www.dpreview.com",
    "A1EVAM02EL8SFB": "www.imbd.com",
    "A1G03W8LWCC2P1": "www.imbd.com",
    "A3M22N3OY5KY7Q": "www.junglee.com",
    "A2RQ2X2B434ALC": "www.junglee.com",
    "A2OZJSJ3C1QC02": "local.amazon.com",
    "A39WRC2IB8YGEK": "www.myhabit.com",
    "A1T5QVC5JXV2VN": "www.myhabit.com",
    "AGQQ0A1HY8X7V": "www.nafc.stores.amazon.com",
    "A1FI6AJ872SLSY": "primenow-us.amazon.com",
    "A1TR4EXBSRW0XC": "primenow-us.amazon.com",
    "AWUFL3O4Q4HZJ": "primenow.amazon.ca",
    "A210MR9VJP84CZ": "primenow.amazon.ca",
    "A2MKBGGTHABQEV": "primenow.amazon.co.jp",
    "A34XX9OGNJ8Z6T": "primenow.amazon.co.jp",
    "AM7DNVYQULIQ5": "primenow.amazon.co.uk",
    "A31S5IEFAOLGXC": "primenow.amazon.co.uk",
    "A1KU16HT7ALXJ0": "primenow.amazon.de",
    "AVFDNTCG43SJ1": "primenow.amazon.de",
    "ACDNLAE5F4JT1": "primenow.amazon.es",
    "A3FQJJS6CFOOV1": "primenow.amazon.es",
    "A2905VW864VWWF": "primenow.amazon.fr",
    "A3A7FBE29Z0H2Y": "primenow.amazon.fr",
    "A1TERGVA4U2MLK": "primenow.amazon.it",
    "A39EJH0AK2BYSI": "primenow.amazon.it",
    "A1PM6DW4PICP7D": "restaurants.amazon.in",
    "A2AKTU3CNEHXR": "restaurants.amazon.in",
    "A2MFUE2XK8ZSSY": "row-eu.primevideo.com",
    "A1D7Z662KHSYZY": "row-eu.primevideo.com",
    "A3K6Y4MI8GDYMT": "roe-eu.primevideo.com",
    "A1MJEQWJOUEPEQ": "roe-eu.primevideo.com",
    "A15PK738MTQHSO": "row-fe.primevideo.com",
    "A9QSUUOYRWVOG": "row-fe.primevideo.com",
    "ART4WZ8MWBX2Y": "row-na.primevideo.com",
    "A3U49GLEJ1PS4Y": "row-na.primevideo.com",
    "AGJTXQVCWGLM9": "ship.amazon.co.uk",
    "A2BR6UVHX99FEC": "ship.amazon.com",
    "A2ACQFO88DPSZS": "ship.amazon.in",
    "A13HAEUNIZD1EO": "www.shopbop.com",
    "AKBI94W7B4CEC": "wholefoods.com",
    "A3LME0HFB219AR": "wholefoods.com",
    "A2HM9SEN36TS7U": "wine.amazon.com",
    "A1NF7LDSKVSYZ7": "wine.amazon.com",
    "A2YXG7NG141OP2": "wireless.amazon.com",
    "A1GA4GP79ABM4D": "wireless.amazon.com",
    "A22ZUEY6FG8RY": "woot.com",
    "ABIJF3C8NHQ6M": "woot.com",
    "A2PO04FIBKA0V6": "splashbaths.webstorepowered.com",
    "A3GVQO73QG4HLY": "tva.afterschool.com",
    "A2PTOWNQL7HN7E": "tva.bookworm.com",
    "ANBJ0JT9LAPZ4": "tva.macsales.com",
    "A3SW0KBROV2X6Q": "tva.shopbop.com",
    "A2ZN6VKQ29XW2B": "tva.vine.com",
    "AZ4B0ZS3LGLX": "cba.amazon.com",
    "A3G6ICTLM20UQX": "csc-trans-isp-na.stores.amazon.com",
    "A2LPTH3J8119AA": "deepone.hostedbyamazon.com",
    "A19D8Z7Z47RU1V": "dejaguar01.amazon.de",
    "A28LUFWO5HYYIZ": "amazonstores.amazon.com",
    "A75P1MG8VMU51": "apa.amazon.co.jp",
    "AZAJMM36N6WQL": "apa.amazon.co.uk",
    "AGWSWK15IEJJ7": "apa.amazon.com",
    "A53RDEWN57UU5": "apa.amazon.de",
    "A3FDG49KKM823Y": "apa.amazon.in",
    "A3C66CJ8C1SW1I": "aps.amazon.stores.amazon.com",
    "AYZ4TAHMVXJLX": "CSACentral.stores.amazon.com",
    "A1XL214U7JA46G": "FPA.beautybar.com",
    "A3I52P3BRBPQB8": "FPA.casa.com",
    "A133VYBJYE8OXU": "FPA.diapers.com",
    "A2IBI0CA6RLSHR": "FPA.soap.com",
    "A18RULUF6B5AN8": "FPA.wag.com",
    "A3T155I11LQFV7": "FPA.yoyo.com",
    "A97B0XXD56IZ1": "tva.woot.com",
    "A1L4CO7C8TPH76": "accseu.stores.amazon.co.uk",
    "A1FG4BLCBHACWL": "amazon-score.stores.amazon.com.tr",
    "A1T0F3SY1TPEAY": "jam-llc.hostedbyamazon.com",
    "AM5XBVGNQNKHG": "loading-dock.webstorepowered.com",
    "A23YMMVOQY6KRT": "localregister.stores.amazon.com",
    "A3RGCJYTOPRINC": "paymentsglobal-csc.amazon.com",
    "A3PY9OQTG31F3H": "paywithamazon.amazon.in",
    "A2UTKXGQLHXMO": "paywithamazon.junglee.com",
    "A30DUSGFTNV3ZQ": "serpents-kiss.hostedbyamazon.com",
    "ARBP9OOSHTCHU": "www.amazon.eg",
    "AUJPM9XGFJRC7": "www.amazon.eg", //Devo
    "A2VIGQ35RCS4UG": "www.amazon.ae",
    "A34GYYCZVDBSIK": "www.amazon.ae", //Devo
    "A17E79C6D8DWNP": "www.amazon.sa",
    "A1MQPSGJ6U9Q54": "www.amazon.sa", //Devo
    "A2NODRKZP88ZB9": "www.amazon.se",
    "A1C3SOZRARQ6R3": "www.amazon.pl",
    "A19VAU5U5O7RUS": "www.amazon.sg",
    "A2817T8MKXE20N": "www.shopbop.com"
}

export const betaLocaleToMarketplaceIDMappings: Record<string, string> = {
    "en_CA": "A2EUQ1WTGCTBG2",
    "es_ES": "AJZF8LZ1EJVJN",
    "en_US": "ATVPDKIKX0DER",
    "en_GB": "A1F83G8C2ARO7P",
    "de_DE": "A1PA6795UKMFR9",
    "en_AU": "A1RNPCQ4K8U27I",
    "es_MX": "A3P3J5A7D2ZVXI ",
    "fr_CA": "A2EUQ1WTGCTBG2",
    "fr_FR": "A13V1IB3VIYZZH",
    "it_IT": "A3HOBANJMCMD83 ",
    "ja_JP": "A1VC38T7YXB528",
    "nl_NL": "A1M3WC0SJ3A38T",
    "pl_PL": "AKY4K4WKH21YQ",
    "pt_BR": "AZXD3QD5B39HD",
    "tr_TR": "A3CQBQD3RGPJR8",
    "zh_CN": "AAHKV2X7AFYLW",
}

export const prodLocaleToMarketplaceIDMappings: Record<string, string> = {
    "en_CA": "A2EUQ1WTGCTBG2",
    "es_ES": "A1RKKUPIHCS9HS",
    "en_US": "ATVPDKIKX0DER",
    "en_GB": "A1F83G8C2ARO7P",
    "de_DE": "A1PA6795UKMFR9",
    "en_AU": "A39IBJ37TRP1C6",
    "es_MX": "A1AM78C64UM0Y8",
    "fr_CA": "A2EUQ1WTGCTBG2",
    "fr_FR": "A13V1IB3VIYZZH",
    "it_IT": "APJ6JRA9NG5V4",
    "ja_JP": "A1VC38T7YXB528",
    "nl_NL": "A1805IZSGTT6HS",
    "pl_PL": "A1C3SOZRARQ6R3",
    "pt_BR": "A2Q3Y263D00KWC",
    "tr_TR": "A33AVAJ2PDY3EV",
    "zh_CN": "AAHKV2X7AFYLW",
}