import React from "react";
import Modal from "@amzn/awsui-components-react/polaris/modal";
import Table from "@amzn/awsui-components-react/polaris/table";
import { ModalTableEntry } from "../Interfaces";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import { i18n } from "../i18n/IntlManager";
import Box from "@amzn/awsui-components-react/polaris/box";
import Link from "@amzn/awsui-components-react/polaris/link";
import Button from "@amzn/awsui-components-react/polaris/button";
import { getModalRedirectUrl } from "../Utilities";

interface Props {
    modalData: string[],
    dismiss: () => void,
    visible: boolean;
    error: string,
    fetching: boolean,
    marketplaceId: string
}



export const FollowUpModal = (props: Props) => {
    const marketplaceId = props.marketplaceId;
    const parseTableData = (data): ModalTableEntry[] => {
        const items = data.map(statistic => {
            return {
                followUpId: statistic.followUpId,
                contactId: statistic.contactId,
                date: statistic.dueDate,
                launched: statistic.Launch.value,
                rescheduled: statistic.ChangeDate.value,
                transferred: statistic.Transfer.value,
                abandoned: statistic.Abandon.value,
            }
        });
        return items;
    }

    const renderFollowUpCell = (followUpid: string, contactId, marketplaceId: string): JSX.Element => {
        const url = getModalRedirectUrl(marketplaceId, contactId);
        return (<Box>
            <Button variant="link" onClick={() => window.open(url)}>
                <Link>{followUpid}
                </Link>
            </Button>
        </Box>)
    }

    const renderModalContent = () => {
        if (props.error) {
            return (<div>{props.error}</div>);
        }
        else if (items.length > 0) {
            return (
                <div style={{ overflowY: "scroll", maxHeight: "500px" }}>
                    <Table
                        trackBy="name"
                        stickyHeader
                        columnDefinitions={[
                            {
                                id: "id",
                                header: i18n("cs_2c_dashboard_id"),
                                cell: e => renderFollowUpCell(e.followUpId, e.contactId, marketplaceId),
                            },
                            {
                                id: "date",
                                header: i18n("cs_2c_dashboard_date"),
                                cell: e => e.date
                            },
                            {
                                id: "launched",
                                header: i18n("cs_2c_dashboard_launched"),
                                cell: e => e.launched
                            },
                            {
                                id: "rescheduled",
                                header: i18n("cs_2c_dashboard_rescheduled"),
                                cell: e => e.rescheduled
                            },
                            {
                                id: "transferred",
                                header: i18n("cs_2c_dashboard_transferred"),
                                cell: e => e.transferred
                            },
                            {
                                id: "abandoned",
                                header: i18n("cs_2c_dashboard_abandoned"),
                                cell: e => e.abandoned
                            },
                        ]}
                        items={items}
                    />
                </div>);
        }

    }

    const dismiss = props.dismiss
    let items: ModalTableEntry[] = [];

    if (props.modalData.length > 0) {
        items = parseTableData(props.modalData);
    }

    return (
        <Modal
            onDismiss={() => dismiss()}
            closeAriaLabel="Close modal"
            visible={props.visible}
            size="max"
        >
            {!props.fetching ?
                renderModalContent()
                :
                <Spinner size="normal" />
            }
        </Modal>
    );
}
