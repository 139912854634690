export const en_GB = {
    "cs_2c_dashboard_abandoned": "Abandoned",
    "cs_2c_dashboard_all": "All",
    "cs_2c_dashboard_current_due_date": "Current Due Date",
    "cs_2c_dashboard_date": "Date",
    "cs_2c_dashboard_days": "days",
    "cs_2c_dashboard_due_current_desc": "the latest due date",
    "cs_2c_dashboard_due_later": "Due Later",
    "cs_2c_dashboard_due_original_desc": "the first due date upon creation",
    "cs_2c_dashboard_due_today": "Due Today",
    "cs_2c_dashboard_end_of_day": "End of Day",
    "cs_2c_dashboard_go": "Go",
    "cs_2c_dashboard_hour": "hour",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "Late",
    "cs_2c_dashboard_launched": "Launched",
    "cs_2c_dashboard_name": "Name",
    "cs_2c_dashboard_next": "Next",
    "cs_2c_dashboard_one_day": "24H",
    "cs_2c_dashboard_one_month": "1M",
    "cs_2c_dashboard_one_week": "1W",
    "cs_2c_dashboard_open": "Open",
    "cs_2c_dashboard_open_all": "All Open",
    "cs_2c_dashboard_open_all_desc": "all open Follow-ups",
    "cs_2c_dashboard_original_due_date": "Original Due Date",
    "cs_2c_dashboard_prev": "Prev",
    "cs_2c_dashboard_previous": "Previous",
    "cs_2c_dashboard_process_history": "Process History",
    "cs_2c_dashboard_process_history_loading": "Loading......",
    "cs_2c_dashboard_process_history_reschedule": "rescheduled this follow-up to",
    "cs_2c_dashboard_process_history_transfer": "transferred this follow-up to",
    "cs_2c_dashboard_process_one_week": "One week",
    "cs_2c_dashboard_refine_by": "Refine by",
    "cs_2c_dashboard_rescheduled": "Rescheduled",
    "cs_2c_dashboard_resolved": "Resolved",
    "cs_2c_dashboard_resolved_all": "All Resolved & Open",
    "cs_2c_dashboard_resolved_all_desc": "all Follow-ups within a given time period, including open and resolved.",
    "cs_2c_dashboard_resolved_tab": "Resolved",
    "cs_2c_dashboard_results": "RESULTS",
    "cs_2c_dashboard_supervisor_view": "Supervisor View",
    "cs_2c_dashboard_timespan": "Time Span",
    "cs_2c_dashboard_tomorrow": "Tomorrow",
    "cs_2c_dashboard_transferred": "Transferred",
    "cs_2c_dashboard_work_category": "Work Category",
    "cs_gn_followup_dashboard": "Followup Dashboard"

}