import React, {useEffect} from "react";
import {authenticate, getPhoenixAPI} from "../api/Phoenix";
import Button from "@amzn/awsui-components-react/polaris/button";
import Tabs from "@amzn/awsui-components-react/polaris/tabs";
import Header from "@amzn/awsui-components-react/polaris/header";
import Input from "@amzn/awsui-components-react/polaris/input";
import OpenTable from "./OpenTable";
import ResolvedTable from "./ResolvedTable";
import {OpenData, ResolvedData} from "../Interfaces";
import {isEmpty} from "lodash"
import {i18n} from "../i18n/IntlManager";
import Spinner from "@amzn/awsui-components-react/polaris/spinner";
import {getActiveFollowUps, getResolvedFollowUps} from "../api/FollowUpApi";
import {LogLevel} from "@amzn/csphoenix-react-client";

const Home = () => {
    const [failureMessage, setFailureMethod] = React.useState<string>("");
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [openData, setOpenData] = React.useState<OpenData>({} as OpenData);
    const [resolvedData, setResolvedData] = React.useState<ResolvedData>({} as ResolvedData);
    const [isOpenFetching, setOpenFetching] = React.useState<boolean>(false);
    const [isResolvedFetching, setResolvedFetching] = React.useState<boolean>(false);
    const [obfuscatedId, setObfuscatedId] = React.useState<string>("");
    const queryString: string = window.location.search;
    const urlParams: URLSearchParams = new URLSearchParams(queryString);
    const marketplaceId = urlParams.get('marketplaceId') as string;

    const onSearchClick = async () => {
        setOpenData({} as OpenData);
        setResolvedData({} as ResolvedData);
        setFailureMethod("");
        if (searchValue) {
            setOpenFetching(true);
            setResolvedFetching(true);
            //generate the JWT token to get user and owner
            if (!getPhoenixAPI().jwtState?.content.sub) {
                try {
                    await getActiveFollowUps(searchValue, "", "");
                } catch {
                    //we are ignoring this because we just want the response to send us back a jwt token irregardless of whether there is an error or not
                }
            }
            const user = getPhoenixAPI().jwtState?.content.sub.split("@")[0];
            const owner = getPhoenixAPI().jwtState?.content.sub.split("@")[1];


            getActiveFollowUps(searchValue, user, owner)
                .then(resData => setOpenData(resData))
                .catch(err => setFailureMethod(err + "\n" + failureMessage))
                .finally(() => setOpenFetching(false));
            getResolvedFollowUps(searchValue, user, owner)
                .then((resData) => {
                    setResolvedData(resData.responseData);
                    setObfuscatedId(resData.obfuscatedId);
                })
                .catch(err => setFailureMethod(err + "\n" + failureMessage))
                .finally(() => setResolvedFetching(false));
        }
    };

    const renderTable = () => {
        var phoenixApi = getPhoenixAPI();
        phoenixApi.log(LogLevel.INFO, "Rendering FollowupDashboard");
        try {
            if (!isEmpty(openData) && !isEmpty(resolvedData)) {
                return (
                    <Tabs
                        tabs={[
                            {
                                label: i18n("cs_2c_dashboard_open"),
                                id: "open",
                                content: <OpenTable obfuscatedId={obfuscatedId} data={openData}
                                                    marketplaceId={marketplaceId}
                                                    owner={getPhoenixAPI().jwtState?.content.sub.split("@")[1]}/>
                            },
                            {
                                label: i18n("cs_2c_dashboard_resolved"),
                                id: "resolved",
                                content: <ResolvedTable obfuscatedId={obfuscatedId} data={resolvedData}
                                                        marketplaceId={marketplaceId}
                                                        owner={getPhoenixAPI().jwtState?.content.sub.split("@")[1]}/>
                            }
                        ]}
                        variant="container"
                    />
                )
            } else if (isOpenFetching || isResolvedFetching) {
                return (<Spinner size="normal"/>)
            }
        } catch (e) {
            phoenixApi.log(LogLevel.ERROR, e);
        }
    }

    useEffect(() => {
        try {
            // calling authenticate to initiate authentication
            authenticate(setFailureMethod);
        } catch (e) {
            getPhoenixAPI().log(LogLevel.ERROR, e);
        }
    }, []);
    return (
        <div>
            <Header variant="h1">{i18n("cs_gn_followup_dashboard")}</Header>
            {/* this message is here to test authentication */}
            <>
                <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ float: "left", paddingRight: "20px" }} >
                        <Input
                            onChange={({ detail }) => setSearchValue(detail.value)}
                            value={searchValue}
                            placeholder="Enter alias"
                        />
                    </div>

                    <Button onClick={onSearchClick} variant="primary" disabled={isOpenFetching || isResolvedFetching}>{i18n("cs_2c_dashboard_go")}</Button>
                </div>
            </>
            {failureMessage && <div> {failureMessage} </div>}


            {renderTable()}
        </div>
    );
};

export default Home;