export const ar_AE = {
    "cs_2c_dashboard_current_due_date": "تاريخ الاستحقاق الحالي",
    "cs_2c_dashboard_days": "أيام",
    "cs_2c_dashboard_due_original_desc": "تاريخ الاستحقاق عند الإنشاء.",
    "cs_2c_dashboard_late": "متأخر",
    "cs_2c_dashboard_one_day": "24 ساعة",
    "cs_2c_dashboard_one_month": "1م",
    "cs_2c_dashboard_previous": "السابق",
    "cs_2c_dashboard_process_history": "محفوظات العمليات",
    "cs_2c_dashboard_process_history_reschedule": "تمت إعادة جدولة هذه المتابعة من",
    "cs_2c_dashboard_transferred": "تم تحويلها"
}