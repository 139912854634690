/*
 * Importing Intl polyfill for Safari.
 * We should consider a conditional loader.
 * @issue
 */
import memoizeFormatConstructor from 'intl-format-cache';
import IntlPolyfill from 'intl';
import IntlMessageFormat from 'intl-messageformat';
import IntlRelativeFormat from 'intl-relativeformat';
// Polyfill for currency.
import {currencyMap} from './locale_data/currencyMap';
// Polyfill for dates and number formats.
// @todo Import from NodeJS-intl-1.2.6 once it is stable.
// Disable ordered-imports so english is imported first and taken as the default.
/* tslint:disable:ordered-imports */
import './locale_data/jsonp/en.js';
import './locale_data/jsonp/de.js';
import './locale_data/jsonp/es.js';
import './locale_data/jsonp/fr.js';
import './locale_data/jsonp/it.js';
import './locale_data/jsonp/ja.js';
import './locale_data/jsonp/ko.js';
import './locale_data/jsonp/pt.js';
import './locale_data/jsonp/zh.js';
// Polyfill for relative dates.
import {languagePolyfill} from './locale_data/relative-dates/languagePolyfill';
import {getPhoenixAPI} from "../api/Phoenix"
import {LogLevel, MetricType} from "@amzn/csphoenix-react-client";

interface IIntlDateTimeOptions {
    localeMatcher?: string;
    timeZone?: string;
    hour12?: string;
    formatMatcher?: string;
    weekday?: string;
    era?: string;
    year?: string;
    month?: string;
    day?: string;
    hour?: string;
    minute?: string;
    second?: string;
    timeZoneName?: string;
}

interface IIntlRelativeDateOptions {
    style?: string;
    units?: string;
}

interface FormatMessageOptions {
    values?: Record<string, unknown>;
    default?: string;
    contactId?: string;
}

/**
 * 18n customer facing API.
 */
export interface IIntlManager {

    /**
     * Translates a number into currency value.
     */
    formatCurrency(value: string): string;

    /**
     *  Formats a date.
     */
    formatDate(value: Date, options?: IIntlDateTimeOptions): string;

    /**
     * Translates a string.
     */
    formatMessage(key: string, options?: FormatMessageOptions): string;

    /**
     * Formats a number.
     */
    formatNumber(value: string): string;

    /**
     *  Formats a relative date.
     */
    formatRelativeDate(value: Date, options?: IIntlRelativeDateOptions): string;

    /**
     * Gets the country code if it was provided.
     */
    getCountryCode(): string | undefined;

    /**
     * Gets the current currency if the country code was provided.
     */
    getCurrency(): string;

    /**
     * Gets the current language.
     */
    getLanguage(): string;

    /**
     * Gets the current locale.
     */
    getLocale(): string;

    /**
     * Sets the current locale.
     */
    setLocale(key: string): void;
}

/**
 * Intl wrapper for FormatJS / ECMA-402 libraries.
 * See https://github.com/tc39/ecma402 for DateTimeFormat and NumberFormat.
 * See https://github.com/yahoo/intl-relativeformat for IntlRelativeFormat.
 * See https://github.com/yahoo/intl-messageformat for IntlMessageFormat.
 */
export default class IntlManager implements IIntlManager {
    static sharedManager;

    protected dateFormatter;
    protected locale;
    protected defaultMessages;
    protected messages;
    protected messageFormatter;
    protected relativeFormatter;
    protected numberFormatter;

    constructor(locale: string, messages: Record<string, unknown>, defaultMessages: Record<string, unknown>) {
        this.dateFormatter = memoizeFormatConstructor(IntlPolyfill.DateTimeFormat);
        this.messageFormatter = memoizeFormatConstructor(IntlMessageFormat);
        this.relativeFormatter = memoizeFormatConstructor(IntlRelativeFormat);
        this.numberFormatter = memoizeFormatConstructor(IntlPolyfill.NumberFormat);
        this.setDefaultMessages(defaultMessages);
        this.setMessages(messages);
        this.setLocale(locale);
    }

    /**
     * Gets the current locale.
     * @returns {String} Current locale.
     */
    public getLocale(): string {
        return this.locale;
    }

    /**
     * Sets the current locale.
     * @param {String} locale Locale value to set.
     */
    public setLocale(locale: string): void {
        if (!IntlManager.isValidLocale(locale)) {
            throw new Error(`${locale} is not a valid locale.`);
        }
        this.locale = locale;
        this.addLocaleData(languagePolyfill[this.getLanguage()]);
    }

    /**
     * Sets the object containing the default fallback translations messages and keys.
     * @param {Object} defaultMessages Object containing the default translations.
     */
    public setDefaultMessages(defaultMessages: Record<string, unknown>): void {
        this.defaultMessages = defaultMessages;
    }

    /**
     * Sets the object containing the translations messages and keys.
     * @param {Object} messages Object containing the translations.
     */
    public setMessages(messages: Record<string, unknown>): void {
        this.messages = messages;
    }

    /**
     * Formats currency based on the current locale.
     * @param {String} value Currency that needs to be formatted.
     * @returns {String} Formatted currency.
     */
    public formatCurrency(value: string): string {
        // To be consistent with formatNumber() and return NaN when not a number
        const formattedNumber = this.formatNumber(value);
        const currentCurrency = this.getCurrency();

        if (currentCurrency && formattedNumber !== 'NaN') {
            return this.numberFormatter(this.locale, {
                currency: currentCurrency,
                style: 'currency',
            }).format(value);
        }
        return formattedNumber;
    }

    /**
     * Formats dates based on the current locale.
     * @param {Date} value Date that needs to be formatted.
     * @param {IIntlDateTimeOptions} options Optional object with user defined options.
     * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat.
     * @todo statically model the options object https://issues.amazon.com/issues/P7459432
     * @returns {String} Formatted date.
     */
    public formatDate(value: Date, options?: IIntlDateTimeOptions): string {
        try {
            // Using language instead of locale because we don't have polyfills for different countries.
            return this.dateFormatter(this.getLanguage(), options).format(value);
        } catch (e) {
            throw new Error('Invalid date value provided to formatDate: ' + value);
        }
    }

    /**
     * Gets the value for a translation id .
     * @param {String} key Translation id from i18n/{locale}.js.
     * @param {FormatMessageOptions} options Object that contains optional variables to be used in the translation.
     * @returns {String} Translated message.
     */
    public formatMessage(key: string, options?: FormatMessageOptions): string {
        const values: Record<string, unknown> = options?.values ? options.values : {};
        const defaultString: string = options?.default ? options.default : 'String not found';
        try {
            const parameters = this.extractParametersFromMessage(key);
            parameters.forEach(parameter => {
                    if (!values[parameter]) {
                        values[parameter] = '';
                    }
                }
            );
            return this.messageFormatter(this.getMessageFromKey(key), this.locale).format(values);
        } catch (e) {
            console.error("Failed to localize string: " + key);
            // Alarms cannot be created on SEARCH expressions, so we need to publish a metric with a fixed name.
            // https://sim.amazon.com/issues/CWCP-4445

            getPhoenixAPI().addMetric('TranslationFailure', 1, MetricType.COUNT);
            const metricName = `TranslationFailure.${key}.${this.locale}`;
            getPhoenixAPI().addMetric(metricName, 1, MetricType.COUNT);

            // No need for contact level metric, but do contact level client side log with exception
            const logMsg = options?.contactId ? metricName + `.${options.contactId}` : metricName;
            getPhoenixAPI().log(LogLevel.ERROR, logMsg + ' ' + e.toString());
            return defaultString;
        }
    }

    private extractParametersFromMessage(key: string): string[] {
        const message = this.getMessageFromKey(key);
        const parameters: string[] = [];
        // We use matchAll and a regex to get all the strings that are inside {}.
        const matches = Array.from(message.matchAll(/{(.*?)}/g));
        for (const match of matches) {
            // In our regex, we used () for the capturing group. In this case, the string inside the {}.
            // The string at index 0 is the full match (including the {}) whereas the string at index 1 is just the captured group.
            parameters.push(match[1]);
        }
        return parameters;
    }

    /**
     * Formats numbers based on the current locale.
     * @param {String} value Number that needs to be formatted.
     * @returns {String} Formatted number.
     */
    public formatNumber(value: string): string {
        return this.numberFormatter(this.locale).format(value);
    }

    /**
     * Formats relative dates based on the current locale.
     * @param {Date} value Date that needs to be formatted.
     * @param {IIntlRelativeDateOptions} options Optional object with user defined options.
     * See: https://github.com/yahoo/intl-relativeformat#custom-options
     * @todo statically model the options object https://issues.amazon.com/issues/P7459432
     * @returns {String} Formatted date.
     */
    public formatRelativeDate(value: Date, options?: IIntlRelativeDateOptions): string {
        try {
            // Using language instead of locale because we don't have polyfills for different countries.
            return this.relativeFormatter(this.getLanguage(), options).format(value);
        } catch (e) {
            throw new Error('Invalid date value provided to formatRelativeDate: ' + value);
        }
    }

    /**
     * Get the country code corresponding to the current locale property.
     * @returns {string} Current country code.
     */
    public getCountryCode(): string | undefined {
        if (this.locale.length === 2 || this.locale.indexOf('-') === -1) {
            // Country was not defined
            return;
        }
        return this.locale.substr(this.locale.indexOf('-') + 1).toUpperCase();
    }

    /**
     * Get the language code corresponding to the current locale property.
     * @returns {string} Current language code.
     */
    public getLanguage(): string {
        return this.locale.substring(0, 2);
    }

    /**
     * Get the currency code corresponding to the current country.
     * @returns {string} Current currency code.
     */
    public getCurrency(): string {
        return currencyMap[this.getCountryCode()];
    }

    /**
     * Gets the ICU Message for a translation id .
     * @param {String} key Translation id from i18n/{locale}.js.
     * @throws Error when an invalid translation key is provided.
     * @returns {String} ICU Message for the key.
     */
    protected getMessageFromKey(key: string): string {
        let message;
        if (this.messages) {
            message = this.messages[key];
        }
        if (!message) {
            if (this.defaultMessages) {
                message = this.defaultMessages[key];
            }
            if (!message) {
                throw new Error('Invalid translation key: ' + key);
            }
        }
        return message;
    }

    /**
     * By default, Intl RelativeFormat ships with the locale data for English (en) built-in
     * to the runtime library. To format data in another locale you need to include its data.
     * See https://github.com/yahoo/intl-relativeformat
     * We dynamically add the polyfill when a language is selected.
     * See RELATIVE_DATE_SUPPORTED_LANGUAGES above.
     * @todo Add the rest of available locale-data polyfills.
     */
    protected addLocaleData(data): void {
        const locales = Array.isArray(data) ? data : [data];
        locales.forEach((localeData) => {
            if (localeData && localeData.locale && !this.hasLocaleData(localeData.locale)) {
                IntlMessageFormat.__addLocaleData(localeData);
                IntlRelativeFormat.__addLocaleData(localeData);
            }
        });
    }

    protected hasLocaleData(localeData): boolean {
        const currentPolyfills = IntlRelativeFormat.__localeData__;
        return Object.keys(currentPolyfills).indexOf(localeData) !== -1;
    }

    /**
     * Checks if the provided locale matches the expected pattern for BCP 47 language tag:
     * [language designator] or [language designator]-[region designator]
     * [language designator]_[region designator] is also valid because that is the format used
     * by Mezzanine's language selector.
     * @todo Allow BCP 47 extensions https://issues.amazon.com/issues/P7445912
     * @param {String} locale to check
     * @returns {Boolean} True if it is a valid locale, false otherwise.
     */
    public static isValidLocale(locale): boolean {
        const localeRegexp = /^(([a-zA-Z]{2})|(^[a-z]{2}(-|_)[A-Z]{2}))$/;
        return locale.match(localeRegexp);
    }
}

// Less verbose option if you just need a simple translation.
export function i18n(key: string, values?: Record<string, unknown>): string {
    return IntlManager.sharedManager.formatMessage(key, values);
}
