import React, { useState } from "react";
import Table from "@amzn/awsui-components-react/polaris/table";
import Box from "@amzn/awsui-components-react/polaris/box";
import { OpenTableEntry, OpenData, OpenStatistic } from "../Interfaces";
import { FollowUpModal } from "./FollowUpModal"
import Button from "@amzn/awsui-components-react/polaris/button";
import { i18n } from "../i18n/IntlManager";
import { getModalStatistics } from "../api/FollowUpApi";
import { getPhoenixAPI } from "../api/Phoenix";
import { getAliasRedirectUrl } from "../Utilities";
import Link from "@amzn/awsui-components-react/polaris/link";
import {useCollection} from "@amzn/awsui-collection-hooks";

interface Props {
    data: OpenData
    marketplaceId: string
    obfuscatedId: string
    owner: string
}

const parseOpenData = (data: OpenData): OpenTableEntry[] => {
    const statisticsList = data.statisticsList;
    const list: OpenStatistic[] = JSON.parse(statisticsList)

    const items = list.map(statistic => {
        return {
            userName: statistic.userName,
            activeLate: Number(statistic.activeLateCurrent.value),
            activeLateIds: statistic.activeLateCurrent.followUpIdList,
            activeLateOriginal: Number(statistic.activeLateOriginal.value),
            activeLateOriginalIds: statistic.activeLateOriginal.followUpIdList,
            activeDueInOneHour: Number(statistic.activeDueInOneHour.value),
            activeDueInOneHourIds: statistic.activeDueInOneHour.followUpIdList,
            activeDueByToday: Number(statistic.activeDueByToday.value),
            activeDueByTodayIds: statistic.activeDueByToday.followUpIdList,
            activeDueByTomorrow: Number(statistic.activeDueByTomorrow.value),
            activeDueByTomorrowIds: statistic.activeDueByTomorrow.followUpIdList,
            activeDueIn7Days: Number(statistic.activeDueIn7Days.value),
            activeDueIn7DaysIds: statistic.activeDueIn7Days.followUpIdList,
            activeAll: Number(statistic.activeAll.value),
            activeAllIds: statistic.activeAll.followUpIdList,
        }
    });
    return items;
}

const OpenTable: React.FC<Props> = (props: Props) => {
    const { items, collectionProps } = useCollection(parseOpenData(props.data), {
        sorting: {}
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<string[]>([]);
    const [modalError, setModalError] = useState<string>("");
    const [modalFetching, setModalFetching] = useState<boolean>(false);


    const onEntryClick = async (entryIds: string[]) => {
        setShowModal(true);
        setModalFetching(true);
        setModalData([]);
        const userId = getPhoenixAPI().jwtState?.content.id;
        try {
            const data = await getModalStatistics(entryIds, userId);
            setModalData(JSON.parse(data));
        } catch (err) {
            setModalError("Error: " + err)
        }
        setModalFetching(false);
    }

    const onDismissHandler = () => {
        setShowModal(false);
    }

    const displayModal = () => {
        return <FollowUpModal marketplaceId={props.marketplaceId} fetching={modalFetching} modalData={modalData} visible={showModal} dismiss={onDismissHandler} error={modalError} />

    }

    const renderEntry = (entryValue: Number, entryIds: string[]): JSX.Element => {
        return entryValue === 0 && entryIds.length === 0 ? (<Box textAlign="center">{entryValue}</Box>) :
            (<Box textAlign="center">
                <Button variant="link" onClick={() => onEntryClick(entryIds)}>
                    <Box color="text-status-error"><u>{entryValue}</u>
                    </Box>
                </Button>
            </Box>)
    }

    const onUserNameClick = async (entryValue: string, marketplaceId: string) => {
        const url = await getAliasRedirectUrl(marketplaceId, entryValue, props.owner)

        return window.open(url)
    }
    
    const renderUserName =  (entryValue: string, marketplaceId: string) => {
        return (
            <Box>
                <Button variant="link" onClick={() => onUserNameClick(entryValue, marketplaceId)}>
                    <Link>{entryValue}
                    </Link>
                </Button>
            </Box>

        )
    };

    return (
        <>
            <Table
                trackBy={"name"}
                {...collectionProps}
                columnDefinitions={[
                    {
                        id: "name",
                        header: i18n("cs_2c_dashboard_name"),
                        cell: e => renderUserName(e.userName, props.marketplaceId),
                        sortingField: "userName"
                    },
                    {
                        id: "activeLateCurrent",
                        header: <Box fontWeight="bold" color="text-status-error">{i18n("cs_2c_dashboard_current_due_date")}</Box>,
                        cell: e => renderEntry(e.activeLate, e.activeLateIds),
                        sortingField: "activeLate"
                    },
                    {
                        id: "activeLateOriginal",
                        header: <Box fontWeight="bold" color="text-status-error">{i18n("cs_2c_dashboard_original_due_date")}</Box>,
                        cell: e => renderEntry(e.activeLateOriginal, e.activeLateOriginalIds),
                        sortingField: "activeLateOriginal"
                    },
                    {
                        id: "activeDueInOneHour",
                        header: "1 " + i18n("cs_2c_dashboard_hour"),
                        cell: e => renderEntry(e.activeDueInOneHour, e.activeDueInOneHourIds),
                        sortingField: "activeDueInOneHour"
                    },
                    {
                        id: "activeDueByToday",
                        header: i18n("cs_2c_dashboard_end_of_day"),
                        cell: e => renderEntry(e.activeDueByToday, e.activeDueByTodayIds),
                        sortingField: "activeDueByToday"
                    },
                    {
                        id: "activeDueByTomorrow",
                        header: i18n("cs_2c_dashboard_tomorrow"),
                        cell: e => renderEntry(e.activeDueByTomorrow, e.activeDueByTomorrowIds),
                        sortingField: "activeDueByTomorrow"
                    },
                    {
                        id: "activeDueIn7Days",
                        header: i18n("cs_2c_dashboard_process_one_week"),
                        cell: e => renderEntry(e.activeDueIn7Days, e.activeDueIn7DaysIds),
                        sortingField: "activeDueIn7Days"
                    },
                    {
                        id: "activeAll",
                        header: i18n("cs_2c_dashboard_open_all"),
                        cell: e => renderEntry(e.activeAll, e.activeAllIds),
                        sortingField: "activeAll"
                    },
                ]}
                items={items}
                loadingText="Loading resources"
            />

            {displayModal()}
        </>
    );
}

export default OpenTable;

