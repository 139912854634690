/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Domain from './Domain';
import Alive from './Alive';
import Auth from './Auth';
import Box from "@amzn/awsui-components-react/polaris/box";
import { getPhoenixAPI } from '../api/Phoenix';
import { LogLevel } from "@amzn/csphoenix-react-client";

const Health: React.FC = () => {
    getPhoenixAPI().log(LogLevel.INFO, "Rendering Health Page");
    return (
        <div>
            <Box variant="h1">Followup Dashboard and Phoenix Gateway Health</Box>
            <Domain/>
            <Alive/>
            <Auth/>
        </div>
    );
};

export default Health;