export const zh_CN = {
    "cs_2c_dashboard_abandoned": "已放弃",
    "cs_2c_dashboard_all": "所有",
    "cs_2c_dashboard_current_due_date": "当前到期日期",
    "cs_2c_dashboard_date": "日期",
    "cs_2c_dashboard_days": "天",
    "cs_2c_dashboard_due_current_desc": "最晚到期日",
    "cs_2c_dashboard_due_later": "稍后到期",
    "cs_2c_dashboard_due_original_desc": "从创建起第一个到期日",
    "cs_2c_dashboard_due_today": "今天到期",
    "cs_2c_dashboard_end_of_day": "一天的结束",
    "cs_2c_dashboard_go": "前往",
    "cs_2c_dashboard_hour": "小时",
    "cs_2c_dashboard_id": "编号",
    "cs_2c_dashboard_late": "延迟",
    "cs_2c_dashboard_launched": "已创建",
    "cs_2c_dashboard_name": "名称",
    "cs_2c_dashboard_next": "下一页",
    "cs_2c_dashboard_one_day": "24 小时",
    "cs_2c_dashboard_one_month": "1 月",
    "cs_2c_dashboard_one_week": "1 周",
    "cs_2c_dashboard_open": "打开",
    "cs_2c_dashboard_open_all": "全部打开",
    "cs_2c_dashboard_open_all_desc": "所有开放的跟进",
    "cs_2c_dashboard_original_due_date": "原始到期日期",
    "cs_2c_dashboard_prev": "上一页",
    "cs_2c_dashboard_previous": "上一页",
    "cs_2c_dashboard_process_history": "过程历史",
    "cs_2c_dashboard_process_history_loading": "正在加载......",
    "cs_2c_dashboard_process_history_reschedule": "将此跟进重新安排至",
    "cs_2c_dashboard_process_history_transfer": "将此跟进重新转至",
    "cs_2c_dashboard_process_one_week": "一周",
    "cs_2c_dashboard_refine_by": "细化依据：",
    "cs_2c_dashboard_rescheduled": "已重新安排",
    "cs_2c_dashboard_resolved": "已受理",
    "cs_2c_dashboard_resolved_all": "全部已受理并打开",
    "cs_2c_dashboard_resolved_all_desc": "在特定期间内，所有开放和已解决的跟进",
    "cs_2c_dashboard_resolved_tab": "已受理",
    "cs_2c_dashboard_results": "结果",
    "cs_2c_dashboard_supervisor_view": "管理者视图",
    "cs_2c_dashboard_timespan": "时间范围",
    "cs_2c_dashboard_tomorrow": "明天",
    "cs_2c_dashboard_transferred": "已转移",
    "cs_2c_dashboard_work_category": "工作类别",
    "cs_gn_followup_dashboard": "跟进面板"
}