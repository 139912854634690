export const zh_TW = {
    "cs_2c_dashboard_abandoned": "已放棄",
    "cs_2c_dashboard_all": "全部",
    "cs_2c_dashboard_current_due_date": "目前的到期日期",
    "cs_2c_dashboard_date": "日期",
    "cs_2c_dashboard_days": "日",
    "cs_2c_dashboard_due_current_desc": "最新到期日",
    "cs_2c_dashboard_due_later": "稍後到期",
    "cs_2c_dashboard_due_original_desc": "建立的第一個到期日",
    "cs_2c_dashboard_due_today": "今日到期",
    "cs_2c_dashboard_end_of_day": "下班",
    "cs_2c_dashboard_go": "轉至",
    "cs_2c_dashboard_hour": "小時",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "最近",
    "cs_2c_dashboard_launched": "年推出",
    "cs_2c_dashboard_name": "名稱",
    "cs_2c_dashboard_next": "下一頁",
    "cs_2c_dashboard_one_day": "24 小時",
    "cs_2c_dashboard_one_month": "1M",
    "cs_2c_dashboard_one_week": "1W",
    "cs_2c_dashboard_open": "打開",
    "cs_2c_dashboard_open_all": "全部打開",
    "cs_2c_dashboard_open_all_desc": "所有未解決的跟進",
    "cs_2c_dashboard_original_due_date": "初始到期日期",
    "cs_2c_dashboard_prev": "上一頁",
    "cs_2c_dashboard_previous": "上一頁",
    "cs_2c_dashboard_process_history": "過程記錄",
    "cs_2c_dashboard_process_history_loading": "載入中......",
    "cs_2c_dashboard_process_history_reschedule": "重新安排該跟進到",
    "cs_2c_dashboard_process_history_transfer": "將此跟進移交給",
    "cs_2c_dashboard_process_one_week": "一週",
    "cs_2c_dashboard_refine_by": "提煉方式",
    "cs_2c_dashboard_rescheduled": "重新安排",
    "cs_2c_dashboard_resolved": "已解決",
    "cs_2c_dashboard_resolved_all": "全部已解決和打開",
    "cs_2c_dashboard_resolved_all_desc": "特定時間內的所有改進，包括未解決的和已解決的。",
    "cs_2c_dashboard_resolved_tab": "已解決",
    "cs_2c_dashboard_results": "結果",
    "cs_2c_dashboard_supervisor_view": "主管意見",
    "cs_2c_dashboard_timespan": "時間範圍",
    "cs_2c_dashboard_tomorrow": "明日",
    "cs_2c_dashboard_transferred": "已傳輸",
    "cs_2c_dashboard_work_category": "工種"
}