import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Home";
import {AuthReceiver} from "@amzn/csphoenix-react-client";
import TestPage from "./containers/TestPage";
import Health from "./health/Health";

export default (
    <Switch>
        <Route exact path="/" >  <Home /> </Route>
        <Route path="/testPage" component={TestPage} />
        <Route path="/health" component={Health} />
        <Route path="/:locale/auth/:cookieName" component={AuthReceiver} />
    </Switch>
);