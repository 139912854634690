export const tr_TR = {
    "cs_2c_dashboard_abandoned": "Vazgeçildi",
    "cs_2c_dashboard_all": "Tümü",
    "cs_2c_dashboard_current_due_date": "Güncel Vade Tarihi",
    "cs_2c_dashboard_date": "Tarih",
    "cs_2c_dashboard_days": "gün",
    "cs_2c_dashboard_due_current_desc": "son vade tarihi",
    "cs_2c_dashboard_due_later": "Vade Dolumu Daha Sonra",
    "cs_2c_dashboard_due_original_desc": "oluşturmadan sonraki ilk vade tarihi",
    "cs_2c_dashboard_due_today": "Vade Dolumu Bugün",
    "cs_2c_dashboard_end_of_day": "Gün Sonu",
    "cs_2c_dashboard_go": "Git",
    "cs_2c_dashboard_hour": "saat",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "Geç",
    "cs_2c_dashboard_launched": "Kullanıma Sokuldu",
    "cs_2c_dashboard_name": "Adı",
    "cs_2c_dashboard_next": "Sonraki",
    "cs_2c_dashboard_one_day": "24h",
    "cs_2c_dashboard_one_month": "1M",
    "cs_2c_dashboard_one_week": "1W",
    "cs_2c_dashboard_open": "Aç",
    "cs_2c_dashboard_open_all": "Açık Olanların Tümü",
    "cs_2c_dashboard_open_all_desc": "tüm açık İzlemeler",
    "cs_2c_dashboard_original_due_date": "Özgün Vade Tarihi",
    "cs_2c_dashboard_prev": "Önceki",
    "cs_2c_dashboard_previous": "Önceki",
    "cs_2c_dashboard_process_history": "İşlem Geçmişi",
    "cs_2c_dashboard_process_history_loading": "Yükleniyor......",
    "cs_2c_dashboard_process_history_reschedule": "bu takip yeniden planlandı:",
    "cs_2c_dashboard_process_history_transfer": "bu takip aktarıldı:",
    "cs_2c_dashboard_process_one_week": "Bir Hafta",
    "cs_2c_dashboard_refine_by": "İyileştirme türü",
    "cs_2c_dashboard_rescheduled": "Yeniden Planlanmış",
    "cs_2c_dashboard_resolved": "Çözümlenmiş",
    "cs_2c_dashboard_resolved_all": "Çözümlenen ve Açık Olanların Tümü",
    "cs_2c_dashboard_resolved_all_desc": "açık ve çözülmüş dahil verilen bir süre içerisindeki tüm İzlemeler",
    "cs_2c_dashboard_resolved_tab": "Çözümlenmiş",
    "cs_2c_dashboard_results": "SONUÇLAR",
    "cs_2c_dashboard_supervisor_view": "Amir Görüşü",
    "cs_2c_dashboard_timespan": "Zaman Süreci",
    "cs_2c_dashboard_tomorrow": "Yarın",
    "cs_2c_dashboard_transferred": "Aktarılan",
    "cs_2c_dashboard_work_category": "Çalışma Kategorisi",
    "cs_gn_followup_dashboard": "Takip Kontrol Paneli"
}