export const cs_CZ = {
    "cs_2c_dashboard_abandoned": "Opuštěno",
    "cs_2c_dashboard_all": "Vše",
    "cs_2c_dashboard_current_due_date": "Aktuální lhůta",
    "cs_2c_dashboard_date": "Datum",
    "cs_2c_dashboard_days": "dní",
    "cs_2c_dashboard_due_current_desc": "nejpozdější datum splatnosti",
    "cs_2c_dashboard_due_later": "Termín později",
    "cs_2c_dashboard_due_original_desc": "první datum splatnosti při vytvoření",
    "cs_2c_dashboard_due_today": "Termín dnes",
    "cs_2c_dashboard_end_of_day": "Konec dne",
    "cs_2c_dashboard_go": "Provést",
    "cs_2c_dashboard_hour": "hodina",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "Zpožděný",
    "cs_2c_dashboard_launched": "Spuštěno",
    "cs_2c_dashboard_name": "Jméno",
    "cs_2c_dashboard_next": "Následující",
    "cs_2c_dashboard_one_day": "h",
    "cs_2c_dashboard_one_month": "m",
    "cs_2c_dashboard_one_week": "1W",
    "cs_2c_dashboard_open": "Otevřít",
    "cs_2c_dashboard_open_all": "Všechny otevřené",
    "cs_2c_dashboard_open_all_desc": "všechny otevřené kontrolní položky",
    "cs_2c_dashboard_original_due_date": "Původní lhůta",
    "cs_2c_dashboard_prev": "Předchozí",
    "cs_2c_dashboard_previous": "Předchozí",
    "cs_2c_dashboard_process_history": "Historie procesu",
    "cs_2c_dashboard_process_history_loading": "Načítá se......",
    "cs_2c_dashboard_process_history_reschedule": "změnil termín této následné položky na",
    "cs_2c_dashboard_process_history_transfer": "přesunul tuto následnou položku na",
    "cs_2c_dashboard_process_one_week": "Jeden týden",
    "cs_2c_dashboard_refine_by": "Zúžit podle",
    "cs_2c_dashboard_rescheduled": "Změna harmonogramu",
    "cs_2c_dashboard_resolved": "Vyřešeno",
    "cs_2c_dashboard_resolved_all": "Všechny vyřešené a otevřené",
    "cs_2c_dashboard_resolved_all_desc": "všechny kontrolní položky v daném časovém intervalu, včetně otevřených a vyřešených.",
    "cs_2c_dashboard_resolved_tab": "Vyřešeno",
    "cs_2c_dashboard_results": "VÝSLEDKY",
    "cs_2c_dashboard_supervisor_view": "Náhled supervizora",
    "cs_2c_dashboard_timespan": "Časový interval",
    "cs_2c_dashboard_tomorrow": "Zítra",
    "cs_2c_dashboard_transferred": "Převedeno",
    "cs_2c_dashboard_work_category": "Pracovní kategorie"
}