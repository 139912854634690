import { clientConfig } from "./api/ClientConfig";
import { getObfuscatedUserId } from "./api/FollowUpApi";
import { getMarketplaceMapping, getModalMapping } from "./api/FollowUpMappings";

/**
 * @param location a Location object of the current window
 * @returns the qualifier (string) of the phoenix endpoint to use
 */
export const getPhoenixQualifier = (location: Location): string | null => {
    const searchParams = new URLSearchParams(location.search);
    return (searchParams.get("pgw") ? searchParams.get("pgw") : location.hostname.split(".")[0]);
};

export const getSubdomain = (location: Location): string => {
    return location.hostname.split(".")[0]
}


// check to see if both cookies are present
export const isLoggedIn = (): boolean => {
    const qualifier = getPhoenixQualifier(window.location) as string;
    const tokenCookieName = clientConfig[qualifier].tokenCookieName;
    const clientStateCookieName = clientConfig[qualifier].clientStateCookieName;
    const allCookies = document.cookie.split(";");
    const hasToken = allCookies.some(cookie => cookie.includes(tokenCookieName));
    const hasClient = allCookies.some(cookie => cookie.includes(clientStateCookieName));
    return hasToken && hasClient;
}


export const getModalRedirectUrl = (marketplaceId: string, id: string): string => {
    const env = getSubdomain(window.location);
    const envUrl = getModalMapping(env);
    const store = getMarketplaceMapping(marketplaceId);
    const redirectRoute = "/gp/communications/issue-manager/?searchByContactID=1&initialCommId=" + id;
    return envUrl + store + redirectRoute;
}

export const getAliasRedirectUrl = async (marketplaceId: string, user: string, owner: string): Promise<string> => {
    const userObfuscated = await getObfuscatedUserId(user, owner)
    const env = getSubdomain(window.location);
    const envUrl = getModalMapping(env);
    const store = getMarketplaceMapping(marketplaceId);
    const redirectRoute = "/gp/home/crm-reportee/?reporteeUserId=" + userObfuscated + "&reporteeUserName=" + user;
    return envUrl + store + redirectRoute;
}