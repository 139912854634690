export const pt_BR = {
    "cs_2c_dashboard_abandoned": "Abandonado",
    "cs_2c_dashboard_all": "Todos",
    "cs_2c_dashboard_current_due_date": "Data de vencimento atual",
    "cs_2c_dashboard_date": "Data",
    "cs_2c_dashboard_days": "dias",
    "cs_2c_dashboard_due_current_desc": "a última data de vencimento",
    "cs_2c_dashboard_due_later": "Vence posteriormente",
    "cs_2c_dashboard_due_original_desc": "a primeira data de vencimento após a criação",
    "cs_2c_dashboard_due_today": "Vence hoje",
    "cs_2c_dashboard_end_of_day": "Final do dia",
    "cs_2c_dashboard_go": "Ir",
    "cs_2c_dashboard_hour": "hora",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "Atrasado",
    "cs_2c_dashboard_launched": "Lançamento",
    "cs_2c_dashboard_name": "Nome",
    "cs_2c_dashboard_next": "Próximo",
    "cs_2c_dashboard_one_day": "24h",
    "cs_2c_dashboard_one_month": "1 mês",
    "cs_2c_dashboard_one_week": "1 semana",
    "cs_2c_dashboard_open": "Abrir",
    "cs_2c_dashboard_open_all": "Todos em aberto",
    "cs_2c_dashboard_open_all_desc": "todos os acompanhamentos abertos",
    "cs_2c_dashboard_original_due_date": "Data de vencimento original",
    "cs_2c_dashboard_prev": "Anterior",
    "cs_2c_dashboard_previous": "Anterior",
    "cs_2c_dashboard_process_history": "Histórico do processo",
    "cs_2c_dashboard_process_history_loading": "Carregando......",
    "cs_2c_dashboard_process_history_reschedule": "reprogramado este acompanhamento para",
    "cs_2c_dashboard_process_history_transfer": "transferido este acompanhamento para",
    "cs_2c_dashboard_process_one_week": "Uma semana",
    "cs_2c_dashboard_refine_by": "Refinar por",
    "cs_2c_dashboard_rescheduled": "Reagendado",
    "cs_2c_dashboard_resolved": "Resolvido",
    "cs_2c_dashboard_resolved_all": "Todos resolvidos e em aberto",
    "cs_2c_dashboard_resolved_all_desc": "todos os acompanhamentos em um determinado período, incluindo abertos e resolvidos.",
    "cs_2c_dashboard_resolved_tab": "Resolvido",
    "cs_2c_dashboard_results": "RESULTADOS",
    "cs_2c_dashboard_supervisor_view": "Modo de exibição de supervisor",
    "cs_2c_dashboard_timespan": "Período",
    "cs_2c_dashboard_tomorrow": "Amanhã",
    "cs_2c_dashboard_transferred": "Transferido",
    "cs_2c_dashboard_work_category": "Categoria do trabalho",
    "cs_gn_followup_dashboard": "Painel de acompanhamento"
}