export const de_DE = {
    "cs_2c_dashboard_abandoned": "Verlassen",
    "cs_2c_dashboard_all": "Alle",
    "cs_2c_dashboard_current_due_date": "Aktueller Fälligkeitstermin",
    "cs_2c_dashboard_date": "Datum",
    "cs_2c_dashboard_days": "Tage",
    "cs_2c_dashboard_due_current_desc": "das aktuellste Fälligkeitsdatum",
    "cs_2c_dashboard_due_later": "Später fällig",
    "cs_2c_dashboard_due_original_desc": "der erste Fälligkeitstermin bei Erstellung",
    "cs_2c_dashboard_due_today": "Heute fällig",
    "cs_2c_dashboard_end_of_day": "Tagesende",
    "cs_2c_dashboard_go": "Los",
    "cs_2c_dashboard_hour": "Stunde",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "Spät",
    "cs_2c_dashboard_launched": "Gestartet",
    "cs_2c_dashboard_name": "Name",
    "cs_2c_dashboard_next": "Weiter",
    "cs_2c_dashboard_one_day": "24 Std",
    "cs_2c_dashboard_one_month": "1 M",
    "cs_2c_dashboard_one_week": "1 W",
    "cs_2c_dashboard_open": "Öffnen",
    "cs_2c_dashboard_open_all": "Alle offenen",
    "cs_2c_dashboard_open_all_desc": "alle offenen Follow-up-Vorgänge",
    "cs_2c_dashboard_original_due_date": "Ursprünglicher Fälligkeitstermin",
    "cs_2c_dashboard_prev": "Vorh",
    "cs_2c_dashboard_previous": "Zurück",
    "cs_2c_dashboard_process_history": "Prozessverlauf",
    "cs_2c_dashboard_process_history_loading": "Wird geladen ...",
    "cs_2c_dashboard_process_history_reschedule": "Nachfassaktion neugeplant auf",
    "cs_2c_dashboard_process_history_transfer": "übertrug diese Nachfassaktion zu",
    "cs_2c_dashboard_process_one_week": "Eine Woche",
    "cs_2c_dashboard_refine_by": "Optimieren durch",
    "cs_2c_dashboard_rescheduled": "Verschoben",
    "cs_2c_dashboard_resolved": "Beigelegt",
    "cs_2c_dashboard_resolved_all": "Alle gelösten und offenen",
    "cs_2c_dashboard_resolved_all_desc": "alle Follow-up-Vorgänge innerhalb eines vorgegebenen Zeitraums, einschließlich offener und abgeschlossener Vorgänge.",
    "cs_2c_dashboard_resolved_tab": "Beigelegt",
    "cs_2c_dashboard_results": "ERGEBNISSE",
    "cs_2c_dashboard_supervisor_view": "Leiteransicht",
    "cs_2c_dashboard_timespan": "Zeitspanne",
    "cs_2c_dashboard_tomorrow": "Morgen",
    "cs_2c_dashboard_transferred": "Übertragen",
    "cs_2c_dashboard_work_category": "Arbeitskategorie"
}