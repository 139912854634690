export const ja_JP = {
    "cs_2c_dashboard_abandoned": "放棄",
    "cs_2c_dashboard_all": "すべて",
    "cs_2c_dashboard_current_due_date": "現在の期日",
    "cs_2c_dashboard_date": "日付",
    "cs_2c_dashboard_days": "日",
    "cs_2c_dashboard_due_current_desc": "最新の期日",
    "cs_2c_dashboard_due_later": "期日（明日以降）",
    "cs_2c_dashboard_due_original_desc": "作成時に基づく最初の期日",
    "cs_2c_dashboard_due_today": "期日（本日）",
    "cs_2c_dashboard_end_of_day": "本日中",
    "cs_2c_dashboard_go": "進む",
    "cs_2c_dashboard_hour": "時間",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "遅延",
    "cs_2c_dashboard_launched": "年開始",
    "cs_2c_dashboard_name": "名",
    "cs_2c_dashboard_next": "次へ",
    "cs_2c_dashboard_one_day": "24 時間",
    "cs_2c_dashboard_one_month": "1M",
    "cs_2c_dashboard_one_week": "1 週間",
    "cs_2c_dashboard_open": "開く",
    "cs_2c_dashboard_open_all": "すべて開く",
    "cs_2c_dashboard_open_all_desc": "進行中のすべてのフォローアップ",
    "cs_2c_dashboard_original_due_date": "オリジナルの期日",
    "cs_2c_dashboard_prev": "前へ",
    "cs_2c_dashboard_previous": "前へ",
    "cs_2c_dashboard_process_history": "プロセス履歴",
    "cs_2c_dashboard_process_history_loading": "読み込み中......",
    "cs_2c_dashboard_process_history_reschedule": "[user A]により、このフォローアップは[a new due date]に再スケジュールされました",
    "cs_2c_dashboard_process_history_transfer": "[user A]により、このフォローアップは[user B]に転送されました",
    "cs_2c_dashboard_process_one_week": "1週間",
    "cs_2c_dashboard_refine_by": "校閲者",
    "cs_2c_dashboard_rescheduled": "再スケジュール済み",
    "cs_2c_dashboard_resolved": "解決済み",
    "cs_2c_dashboard_resolved_all": "すべての解決済み＆開く",
    "cs_2c_dashboard_resolved_all_desc": "所定の期間内のすべてのフォローアップ（進行中のものと終了したものを含む）。",
    "cs_2c_dashboard_resolved_tab": "解決済み",
    "cs_2c_dashboard_results": "結果",
    "cs_2c_dashboard_supervisor_view": "スーパーバイザー ビュー",
    "cs_2c_dashboard_timespan": "時間のスパン",
    "cs_2c_dashboard_tomorrow": "明日",
    "cs_2c_dashboard_transferred": "転送",
    "cs_2c_dashboard_work_category": "作業カテゴリ",
    "cs_gn_followup_dashboard": "フォローアップダッシュボード"
}