import React from 'react';
import Box from "@amzn/awsui-components-react/polaris/box";
import { getPhoenixAPI } from '../api/Phoenix';
import { LogLevel } from "@amzn/csphoenix-react-client";

const TestPage: React.FC = () => {
    getPhoenixAPI().log(LogLevel.INFO, "Rendering TestPage");
    return (
        <div>
            <Box variant="h1">FollowUp Dashboard - TestPage</Box>
            <ul>
                <li><a href={'../health'}>Application Health</a></li>
            </ul>
        </div>
    );
};

export default TestPage;