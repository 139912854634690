export const fr_FR = {
    "cs_2c_dashboard_abandoned": "Abandonné",
    "cs_2c_dashboard_all": "Tous",
    "cs_2c_dashboard_current_due_date": "Date limite actuelle",
    "cs_2c_dashboard_date": "Date",
    "cs_2c_dashboard_days": "jours",
    "cs_2c_dashboard_due_current_desc": "dernière date limite",
    "cs_2c_dashboard_due_later": "Dû plus tard",
    "cs_2c_dashboard_due_original_desc": "la première date limite après la création",
    "cs_2c_dashboard_due_today": "Dû aujourd'hui",
    "cs_2c_dashboard_end_of_day": "Fin de journée",
    "cs_2c_dashboard_go": "Aller",
    "cs_2c_dashboard_hour": "heure",
    "cs_2c_dashboard_id": "ID",
    "cs_2c_dashboard_late": "En retard",
    "cs_2c_dashboard_launched": "Lancé",
    "cs_2c_dashboard_name": "Nom",
    "cs_2c_dashboard_next": "Suivant",
    "cs_2c_dashboard_one_day": "24 h",
    "cs_2c_dashboard_one_month": "1 m",
    "cs_2c_dashboard_one_week": "1 W",
    "cs_2c_dashboard_open": "Ouvrir",
    "cs_2c_dashboard_open_all": "Tous ouverts",
    "cs_2c_dashboard_open_all_desc": "tous les suivis ouverts",
    "cs_2c_dashboard_original_due_date": "Date limite initiale",
    "cs_2c_dashboard_prev": "Préc",
    "cs_2c_dashboard_previous": "Précédent",
    "cs_2c_dashboard_process_history": "Historique de traitement",
    "cs_2c_dashboard_process_history_loading": "Chargement en cours......",
    "cs_2c_dashboard_process_history_reschedule": "suivi planifié à nouveau pour",
    "cs_2c_dashboard_process_history_transfer": "suivi transféré à",
    "cs_2c_dashboard_process_one_week": "Une semaine",
    "cs_2c_dashboard_refine_by": "Affiner par",
    "cs_2c_dashboard_rescheduled": "Reporté",
    "cs_2c_dashboard_resolved": "Résolu",
    "cs_2c_dashboard_resolved_all": "Tous ouverts et résolus",
    "cs_2c_dashboard_resolved_all_desc": "tous les suivis dans une période donnée, y compris les suivis ouverts et résolus.",
    "cs_2c_dashboard_resolved_tab": "Résolu",
    "cs_2c_dashboard_results": "RÉSULTATS",
    "cs_2c_dashboard_supervisor_view": "Vue superviseur",
    "cs_2c_dashboard_timespan": "Intervalle",
    "cs_2c_dashboard_tomorrow": "Demain",
    "cs_2c_dashboard_transferred": "Transféré",
    "cs_2c_dashboard_work_category": "Catégorie de travail",
    "cs_gn_followup_dashboard": "Tableau de bord de suivi"
}